
// @ts-nocheck


export const localeCodes =  [
  "de",
  "en",
  "es",
  "fr",
  "id",
  "it",
  "ja",
  "ko",
  "pl",
  "pt",
  "ru",
  "tr",
  "uk",
  "zh"
]

export const localeLoaders = {
  de: [
    {
      key: "locale_de_46json_5d6b188e",
      load: () => import("#nuxt-i18n/5d6b188e" /* webpackChunkName: "locale_de_46json_5d6b188e" */),
      cache: true
    }
  ],
  en: [
    {
      key: "locale_en_46json_7a337b0b",
      load: () => import("#nuxt-i18n/7a337b0b" /* webpackChunkName: "locale_en_46json_7a337b0b" */),
      cache: true
    }
  ],
  es: [
    {
      key: "locale_es_46json_13676751",
      load: () => import("#nuxt-i18n/13676751" /* webpackChunkName: "locale_es_46json_13676751" */),
      cache: true
    }
  ],
  fr: [
    {
      key: "locale_fr_46json_c48dae51",
      load: () => import("#nuxt-i18n/c48dae51" /* webpackChunkName: "locale_fr_46json_c48dae51" */),
      cache: true
    }
  ],
  id: [
    {
      key: "locale_id_46json_6e639941",
      load: () => import("#nuxt-i18n/6e639941" /* webpackChunkName: "locale_id_46json_6e639941" */),
      cache: true
    }
  ],
  it: [
    {
      key: "locale_it_46json_c03922f9",
      load: () => import("#nuxt-i18n/c03922f9" /* webpackChunkName: "locale_it_46json_c03922f9" */),
      cache: true
    }
  ],
  ja: [
    {
      key: "locale_ja_46json_ee24f195",
      load: () => import("#nuxt-i18n/ee24f195" /* webpackChunkName: "locale_ja_46json_ee24f195" */),
      cache: true
    }
  ],
  ko: [
    {
      key: "locale_ko_46json_605307c0",
      load: () => import("#nuxt-i18n/605307c0" /* webpackChunkName: "locale_ko_46json_605307c0" */),
      cache: true
    }
  ],
  pl: [
    {
      key: "locale_pl_46json_b459e583",
      load: () => import("#nuxt-i18n/b459e583" /* webpackChunkName: "locale_pl_46json_b459e583" */),
      cache: true
    }
  ],
  pt: [
    {
      key: "locale_pt_46json_204682e6",
      load: () => import("#nuxt-i18n/204682e6" /* webpackChunkName: "locale_pt_46json_204682e6" */),
      cache: true
    }
  ],
  ru: [
    {
      key: "locale_ru_46json_69325769",
      load: () => import("#nuxt-i18n/69325769" /* webpackChunkName: "locale_ru_46json_69325769" */),
      cache: true
    }
  ],
  tr: [
    {
      key: "locale_tr_46json_eb2b6536",
      load: () => import("#nuxt-i18n/eb2b6536" /* webpackChunkName: "locale_tr_46json_eb2b6536" */),
      cache: true
    }
  ],
  uk: [
    {
      key: "locale_uk_46json_be66b0eb",
      load: () => import("#nuxt-i18n/be66b0eb" /* webpackChunkName: "locale_uk_46json_be66b0eb" */),
      cache: true
    }
  ],
  zh: [
    {
      key: "locale_zh_46json_7264fb95",
      load: () => import("#nuxt-i18n/7264fb95" /* webpackChunkName: "locale_zh_46json_7264fb95" */),
      cache: true
    }
  ]
}

export const vueI18nConfigs = [
  () => import("#nuxt-i18n/6daef7fe" /* webpackChunkName: "config_i18n_46config_46ts_6daef7fe" */)
]

export const nuxtI18nOptions = {
  restructureDir: "i18n",
  experimental: {
    localeDetector: "",
    switchLocalePathLinkSSR: false,
    autoImportTranslationFunctions: false,
    typedPages: true,
    typedOptionsAndMessages: false,
    generatedLocaleFilePathFormat: "absolute",
    alternateLinkCanonicalQueries: false,
    hmr: true
  },
  bundle: {
    compositionOnly: true,
    runtimeOnly: false,
    fullInstall: true,
    dropMessageCompiler: false,
    optimizeTranslationDirective: true
  },
  compilation: {
    jit: true,
    strictMessage: true,
    escapeHtml: false
  },
  customBlocks: {
    defaultSFCLang: "json",
    globalSFCScope: false
  },
  locales: [
    {
      code: "de",
      language: "de-DE",
      name: "Deutsch",
      files: [
        "/app/apps/website/i18n/locales/de.json"
      ]
    },
    {
      code: "en",
      language: "en-US",
      name: "English",
      files: [
        "/app/apps/website/i18n/locales/en.json"
      ]
    },
    {
      code: "es",
      language: "es-ES",
      name: "Español",
      files: [
        "/app/apps/website/i18n/locales/es.json"
      ]
    },
    {
      code: "fr",
      language: "fr-FR",
      name: "Français",
      files: [
        "/app/apps/website/i18n/locales/fr.json"
      ]
    },
    {
      code: "id",
      language: "id-ID",
      name: "Bahasa Indonesia",
      files: [
        "/app/apps/website/i18n/locales/id.json"
      ]
    },
    {
      code: "it",
      language: "it-IT",
      name: "Italiano",
      files: [
        "/app/apps/website/i18n/locales/it.json"
      ]
    },
    {
      code: "ja",
      language: "ja-JP",
      name: "日本語",
      files: [
        "/app/apps/website/i18n/locales/ja.json"
      ]
    },
    {
      code: "ko",
      language: "ko-KR",
      name: "한국어",
      files: [
        "/app/apps/website/i18n/locales/ko.json"
      ]
    },
    {
      code: "pl",
      language: "pl-PL",
      name: "Polski",
      files: [
        "/app/apps/website/i18n/locales/pl.json"
      ]
    },
    {
      code: "pt",
      language: "pt-PT",
      name: "Português",
      files: [
        "/app/apps/website/i18n/locales/pt.json"
      ]
    },
    {
      code: "ru",
      language: "ru-RU",
      name: "Русский",
      files: [
        "/app/apps/website/i18n/locales/ru.json"
      ]
    },
    {
      code: "tr",
      language: "tr-TR",
      name: "Türkçe",
      files: [
        "/app/apps/website/i18n/locales/tr.json"
      ]
    },
    {
      code: "uk",
      language: "uk-UA",
      name: "Українська",
      files: [
        "/app/apps/website/i18n/locales/uk.json"
      ]
    },
    {
      code: "zh",
      language: "zh-CN",
      name: "中文",
      files: [
        "/app/apps/website/i18n/locales/zh.json"
      ]
    }
  ],
  defaultLocale: "en",
  defaultDirection: "ltr",
  routesNameSeparator: "___",
  trailingSlash: false,
  defaultLocaleRouteNameSuffix: "default",
  strategy: "prefix_except_default",
  lazy: true,
  langDir: "locales",
  rootRedirect: undefined,
  detectBrowserLanguage: {
    alwaysRedirect: true,
    cookieCrossOrigin: false,
    cookieDomain: null,
    cookieKey: "i18n_redirected_v2",
    cookieSecure: false,
    fallbackLocale: "",
    redirectOn: "root",
    useCookie: true
  },
  differentDomains: false,
  baseUrl: "",
  customRoutes: "page",
  pages: {},
  skipSettingLocaleOnNavigate: false,
  types: "composition",
  debug: false,
  parallelPlugin: false,
  multiDomainLocales: false,
  i18nModules: []
}

export const normalizedLocales = [
  {
    code: "de",
    language: "de-DE",
    name: "Deutsch",
    files: [
      {
        path: "/app/apps/website/i18n/locales/de.json",
        cache: undefined
      }
    ]
  },
  {
    code: "en",
    language: "en-US",
    name: "English",
    files: [
      {
        path: "/app/apps/website/i18n/locales/en.json",
        cache: undefined
      }
    ]
  },
  {
    code: "es",
    language: "es-ES",
    name: "Español",
    files: [
      {
        path: "/app/apps/website/i18n/locales/es.json",
        cache: undefined
      }
    ]
  },
  {
    code: "fr",
    language: "fr-FR",
    name: "Français",
    files: [
      {
        path: "/app/apps/website/i18n/locales/fr.json",
        cache: undefined
      }
    ]
  },
  {
    code: "id",
    language: "id-ID",
    name: "Bahasa Indonesia",
    files: [
      {
        path: "/app/apps/website/i18n/locales/id.json",
        cache: undefined
      }
    ]
  },
  {
    code: "it",
    language: "it-IT",
    name: "Italiano",
    files: [
      {
        path: "/app/apps/website/i18n/locales/it.json",
        cache: undefined
      }
    ]
  },
  {
    code: "ja",
    language: "ja-JP",
    name: "日本語",
    files: [
      {
        path: "/app/apps/website/i18n/locales/ja.json",
        cache: undefined
      }
    ]
  },
  {
    code: "ko",
    language: "ko-KR",
    name: "한국어",
    files: [
      {
        path: "/app/apps/website/i18n/locales/ko.json",
        cache: undefined
      }
    ]
  },
  {
    code: "pl",
    language: "pl-PL",
    name: "Polski",
    files: [
      {
        path: "/app/apps/website/i18n/locales/pl.json",
        cache: undefined
      }
    ]
  },
  {
    code: "pt",
    language: "pt-PT",
    name: "Português",
    files: [
      {
        path: "/app/apps/website/i18n/locales/pt.json",
        cache: undefined
      }
    ]
  },
  {
    code: "ru",
    language: "ru-RU",
    name: "Русский",
    files: [
      {
        path: "/app/apps/website/i18n/locales/ru.json",
        cache: undefined
      }
    ]
  },
  {
    code: "tr",
    language: "tr-TR",
    name: "Türkçe",
    files: [
      {
        path: "/app/apps/website/i18n/locales/tr.json",
        cache: undefined
      }
    ]
  },
  {
    code: "uk",
    language: "uk-UA",
    name: "Українська",
    files: [
      {
        path: "/app/apps/website/i18n/locales/uk.json",
        cache: undefined
      }
    ]
  },
  {
    code: "zh",
    language: "zh-CN",
    name: "中文",
    files: [
      {
        path: "/app/apps/website/i18n/locales/zh.json",
        cache: undefined
      }
    ]
  }
]

export const NUXT_I18N_MODULE_ID = "@nuxtjs/i18n"
export const parallelPlugin = false
export const isSSG = false
export const hasPages = true

export const DEFAULT_COOKIE_KEY = "i18n_redirected"
export const DEFAULT_DYNAMIC_PARAMS_KEY = "nuxtI18nInternal"
export const SWITCH_LOCALE_PATH_LINK_IDENTIFIER = "nuxt-i18n-slp"
/** client **/

/** client-end **/