// https://github.com/fumeapp/dayjs/issues/44#issuecomment-2527442625
export default defineNuxtPlugin((app) => {
  // set locale on app created
  app.hook('app:created', () => {
    app.$dayjs.locale(app.$i18n.locale.value)
  })

  // set locale on locale switched
  app.hook('i18n:localeSwitched', ({ newLocale: locale }) =>
    app.$dayjs.locale(locale)
  )
})
