/**
 * Analytics composable
 */
export function useConsent() {
  const acceptedCookies = useCookie('consent', {
    default: () => (import.meta.server ? false : isInEu() ? false : true),
  })

  const trigger = useScriptTriggerConsent({
    consent: acceptedCookies,
  })

  return {
    trigger,
    acceptedCookies,
  }
}

function useGoogleAnalytics(input: GoogleAnalyticsInput) {
  const { trigger } = useConsent()

  return useScriptGoogleAnalytics({
    ...input,
    scriptOptions: {
      trigger,
      bundle: true,
    },
  })
}

function useMetaPixel(input: MetaPixelInput) {
  const { trigger } = useConsent()

  return useScriptMetaPixel({
    ...input,
    scriptOptions: {
      trigger,
      bundle: true,
    },
  })
}

/**
 * Use App analytics
 */
export const useAnalytics = createSharedComposable(() => {
  const { website } = useAppConfig()

  // meta pixel
  const pixelId = website.analytic?.meta_pixel_id
  const pixel = pixelId
    ? useMetaPixel({
        id: pixelId,
        key: pixelId,
      })
    : null

  // google analytics
  const googleAnalytics = website.analytic?.google_analytics_id
    ? useGoogleAnalytics({
        id: website.analytic.google_analytics_id,
        key: website.analytic.google_analytics_id,
      })
    : null

  return {
    pixel,
    googleAnalytics,
  }
})

/**
 * Use Consumer analytics
 */
export function useConsumerMetaPixel({
  id,
  input = {},
}: {
  id?: MaybeRefOrGetter<string | undefined>
  input?: Omit<MetaPixelInput, 'id'>
}) {
  const pixelId = toValue(id)

  if (!pixelId) {
    return
  }

  const consumerPixel = useMetaPixel({
    id: pixelId,
    ...input,
    key: pixelId,
  })

  if (consumerPixel.status.value !== 'loaded') {
    consumerPixel.proxy.fbq('init', pixelId)
    consumerPixel.proxy.fbq('track', 'PageView')
  }

  return consumerPixel
}

/**
 * Use Consumer Google Analytics
 */
export function useConsumerGoogleAnalytics({
  id,
  input = {},
}: {
  id?: MaybeRefOrGetter<string | undefined>
  input?: Omit<GoogleAnalyticsInput, 'id'>
}) {
  const gtagId = toValue(id)

  if (!gtagId) {
    return
  }

  const consumerGoogleAnalytics = useGoogleAnalytics({
    id: gtagId,
    ...input,
    key: gtagId,
  })

  if (consumerGoogleAnalytics.status.value !== 'loaded') {
    // @ts-ignore
    consumerGoogleAnalytics.proxy.gtag('config', gtagId)
    // @ts-ignore
    consumerGoogleAnalytics.proxy.gtag('event', 'PageView')
  }

  return consumerGoogleAnalytics
}
