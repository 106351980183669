import { defineNuxtRouteMiddleware, navigateTo } from 'nuxt/app'

export default defineNuxtRouteMiddleware((to, from) => {
  const isServiceRoute = /\/api\/.|\/_ipx/.test(to.path)

  if (isServiceRoute) console.log({ isServiceRoute })

  let path = to.path

  /**
   * IS main page skip
   */
  if (path === '/' || isServiceRoute) return

  /**
   * Strict no-trailing-slash rule
   * @link https://deltener.com/blog/creating-redirects-with-nuxt/
   * @link https://github.com/nuxt/nuxt/issues/15462#issuecomment-1407374859
   */
  if (path.endsWith('/')) {
    path = path.replace(/\/+$/, '') || '/'
  }

  /**
   * Redirect /demos/* - 301
   * #1078
   */
  if (path !== '/demos' && path.includes('/demos/')) {
    path = path.replace('/demos/', '/')
  }

  /**
   * Redirect ar/hi/be to / - 301
   * #1702
   */
  if (/^\/(ar|hi|be)\//.test(path)) {
    path = path.replace(/^\/(ar|hi|be)\//, '/')
  }

  /**
   * Redirect to lowercase
   * #1078
   */
  if (!isServiceRoute && path.match(/[A-Z]/)) {
    path = path.toLowerCase()
  }

  if (path === to.path) return

  console.log('Redirecting to', { path, to: to.path })

  return navigateTo({ path }, { redirectCode: 301 })
})
