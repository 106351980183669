<script lang="ts" setup>
const { t } = useI18n()

const acceptedCookies = useCookie('consent', {
  default: () => false,
})

const scriptConsent = useScriptTriggerConsent({
  consent: acceptedCookies,
})

function acceptCookies() {
  scriptConsent.accept()
  acceptedCookies.value = true
}
</script>

<template>
  <div
    v-if="!acceptedCookies"
    id="cookie-consent"
    class="flex flex-row gap-2 px-2.5 py-4 min-[414px]:gap-4.5 min-[414px]:px-5 items-center bg-[#26262699] backdrop-blur-8 fixed bottom-0 w-full left-0 md:w-auto md:left-8 md:rounded-full md:bottom-8 z-100001"
  >
    <div class="flex flex-row items-center">
      <i
        class="i-belong:cookie-icon min-w-4.5 h-4.5 min-[414px]:min-w-6 min-[414px]:h-6"
      />

      <div class="text-xs min-[414px]:text-sm text-[#F2F2F2] ml-2">
        {{ t('allow-cookies') }}
      </div>
    </div>

    <button
      @click="acceptedCookies = false"
      class="px-4 py-1.5 text-sm uppercase font-600 border-1 border-solid border-[#E0E0E0] rounded-full hover:opacity-60 active:opacity-40"
    >
      {{ t('no') }}
    </button>

    <div
      style="
        background: linear-gradient(
          90deg,
          #ffb0f0 0%,
          #c88bc4 23.96%,
          #7540ee 53.65%,
          #4b7de0 76.56%,
          #a0c0ff 100%
        );
      "
      class="rounded-full p-0.25 hover:opacity-60 active:opacity-40"
    >
      <button
        @click="acceptCookies()"
        class="bg-black font-500 px-3.25 py-1 rounded-full"
      >
        {{ t('yes') }}
      </button>
    </div>
  </div>
</template>
