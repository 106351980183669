
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as aiuPxt2t5xMjKDL2Hkl0_82MEtwnC_45pjUwlbNPvqYge1sMeta } from "/app/apps/website/app/pages/ai.vue?macro=true";
import { default as app0_FBI_45mBZ3hro5E_OwedZAu2ovJp4GzQLC9uaLootdQMeta } from "/app/apps/website/app/pages/app.vue?macro=true";
import { default as nftXgXbxht52xZMd2_Hbc_PPbmv_fjr9jDgfG2LD7NljBMMeta } from "/app/apps/website/app/pages/nft.vue?macro=true";
import { default as indexEZbcNnAsSV0_456BZyWWBnObQXHLJd_45R7kYAs9U5oVmNAMeta } from "/app/apps/website/app/pages/index.vue?macro=true";
import { default as termsgEecWgorFGdsJsnV4hEuqTrqVim0teDfl1KpeFgbUg8Meta } from "/app/apps/website/app/pages/terms.vue?macro=true";
import { default as tokenE4SBM_tw6_m7FlEQzH8R6DwcHM_8FyN_45KOi_HWRhKscMeta } from "/app/apps/website/app/pages/token.vue?macro=true";
import { default as gamingJEMPRDYiYyDb0RBnS7QpdH8XYfWK_45rYqp50YX5Pj_p0Meta } from "/app/apps/website/app/pages/gaming.vue?macro=true";
import { default as privacyxfEsP4J1o_45iMaHK0ziLjI1Asj0m2ofcOVyPU9xYbllAMeta } from "/app/apps/website/app/pages/privacy.vue?macro=true";
import { default as download04yHreLZN1ufh_mEs6Ug4_BMjsu52NznVVaeeYK8ei8Meta } from "/app/apps/website/app/pages/download.vue?macro=true";
import { default as indexVTQH5xDgF8hb7Lnk_452SkTtujt5OHnhyEDqRTwKdOy2IMeta } from "/app/apps/website/app/pages/blog/index.vue?macro=true";
import { default as icondlGWkJ93GVeDizO60c_45SHrHeMqSMyHvxPhii8Kx_45geEMeta } from "/app/apps/website/app/pages/debug/icon.vue?macro=true";
import { default as indexuAOAK4rbVv6BTcwJWW6N2gaJ3lTPGYxQm8RCYO2CkbwMeta } from "/app/apps/website/app/pages/hubs/index.vue?macro=true";
import { default as indexXv_45ObRlzw2EHbhRBWfOKxDdakqyRpLgn4oOoUbpJMfQMeta } from "/app/apps/website/app/pages/debug/index.vue?macro=true";
import { default as indexhUdoXrmrmaE3NHUXASy2O2aTMZyWJ7f9SidKCu9KNQoMeta } from "/app/apps/website/app/pages/demos/index.vue?macro=true";
import { default as nft_45ticketszcQw_45PVX1uqnYfTY0rjps5tm0eLkzhZmJqRnqG4toYcMeta } from "/app/apps/website/app/pages/nft-tickets.vue?macro=true";
import { default as creator_45passyRFT2geJUTpTFE3P0Oi_cTk_45RzDVXy1315jYKY0pWdsMeta } from "/app/apps/website/app/pages/creator-pass.vue?macro=true";
import { default as lottieZA3aAdRTvhPbYDeEV9OqbSjgNgZspccWxmwY1Dj40tgMeta } from "/app/apps/website/app/pages/debug/lottie.vue?macro=true";
import { default as _91slug_93uH10aIj0yuH0yUUq1NvLhLNqDLBq3BeV44TmBR1Ni_45QMeta } from "/app/apps/website/app/pages/demos/[slug].vue?macro=true";
import { default as indexUXpeS9Sk3eKBe3zIaaWzScXDjrPg7LY6vnd2_9Jk_45ScMeta } from "/app/apps/website/app/pages/events/index.vue?macro=true";
import { default as founder_45passMcrn3t97twvrT8N2dhvNEsx4_k85Y0Hlo_45kDDhHzjsQMeta } from "/app/apps/website/app/pages/founder-pass.vue?macro=true";
import { default as indexMPNbdBu3fRhQmZB53o1PVtJg_45NbQwX_45Sweo15saQkbYMeta } from "/app/apps/website/app/pages/how-to/index.vue?macro=true";
import { default as token_45gatingCon7oYQ6R5AnW5O1yUIfzucnd1qKyJOTWqsPbRoG8WAMeta } from "/app/apps/website/app/pages/token-gating.vue?macro=true";
import { default as _91slug_935JLsj_45sG1MFqPBnDVNxEed3fWfACfk9zKxS8Rj_45VwagMeta } from "/app/apps/website/app/pages/how-to/[slug].vue?macro=true";
import { default as _91hub_slug_93pbzg9lhD3MgL7lKfkITC8NP5ZGPPwnHS_453nWTp3NdQAMeta } from "/app/apps/website/app/pages/hubs/[hub_slug].vue?macro=true";
import { default as _91blog_slug_93pGdczDTP5NzL_Iehcfma5S9X41KW0stGD0JZBCYicy4Meta } from "/app/apps/website/app/pages/blog/[blog_slug].vue?macro=true";
import { default as paid_45content_45nftS4SUctpC6b0DJHSn9jiYxLmSqyHvdPzREw1DmlHoYMsMeta } from "/app/apps/website/app/pages/paid-content-nft.vue?macro=true";
import { default as _91hubId_93f_45OcvVom2_45DXORo_45b1PlaHA0ldyrFORb2anlspNKvBAMeta } from "/app/apps/website/app/pages/debug/hub/[hubId].vue?macro=true";
import { default as _91event_slug_936OcSwlcPsfyxVzUMON__ZGf_45EYaDUpH2xHgnkBUaa_45IMeta } from "/app/apps/website/app/pages/events/[event_slug].vue?macro=true";
import { default as mighty_45networks_45alternativeRhcGc8CQzNbUDhvQQ2nb3lrkiidX9euxnZorRCklBrgMeta } from "/app/apps/website/app/pages/mighty-networks-alternative.vue?macro=true";
import { default as component_45stubj39ROnQbHmm0ofaoc5O5P6SgTynbhEUJQMKn6soiApUMeta } from "/app/node_modules/.pnpm/nuxt@3.16.1_@biomejs+biome@1.9.4_@capacitor+preferences@6.0.3_@capacitor+core@7.1.0__@parcel+_5lshcovbuwimkum4fpehb5juf4/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stubj39ROnQbHmm0ofaoc5O5P6SgTynbhEUJQMKn6soiApU } from "/app/node_modules/.pnpm/nuxt@3.16.1_@biomejs+biome@1.9.4_@capacitor+preferences@6.0.3_@capacitor+core@7.1.0__@parcel+_5lshcovbuwimkum4fpehb5juf4/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "ai___de",
    path: "/de/ai",
    meta: aiuPxt2t5xMjKDL2Hkl0_82MEtwnC_45pjUwlbNPvqYge1sMeta || {},
    component: () => import("/app/apps/website/app/pages/ai.vue")
  },
  {
    name: "ai___en",
    path: "/ai",
    meta: aiuPxt2t5xMjKDL2Hkl0_82MEtwnC_45pjUwlbNPvqYge1sMeta || {},
    component: () => import("/app/apps/website/app/pages/ai.vue")
  },
  {
    name: "ai___es",
    path: "/es/ai",
    meta: aiuPxt2t5xMjKDL2Hkl0_82MEtwnC_45pjUwlbNPvqYge1sMeta || {},
    component: () => import("/app/apps/website/app/pages/ai.vue")
  },
  {
    name: "ai___fr",
    path: "/fr/ai",
    meta: aiuPxt2t5xMjKDL2Hkl0_82MEtwnC_45pjUwlbNPvqYge1sMeta || {},
    component: () => import("/app/apps/website/app/pages/ai.vue")
  },
  {
    name: "ai___id",
    path: "/id/ai",
    meta: aiuPxt2t5xMjKDL2Hkl0_82MEtwnC_45pjUwlbNPvqYge1sMeta || {},
    component: () => import("/app/apps/website/app/pages/ai.vue")
  },
  {
    name: "ai___it",
    path: "/it/ai",
    meta: aiuPxt2t5xMjKDL2Hkl0_82MEtwnC_45pjUwlbNPvqYge1sMeta || {},
    component: () => import("/app/apps/website/app/pages/ai.vue")
  },
  {
    name: "ai___ja",
    path: "/ja/ai",
    meta: aiuPxt2t5xMjKDL2Hkl0_82MEtwnC_45pjUwlbNPvqYge1sMeta || {},
    component: () => import("/app/apps/website/app/pages/ai.vue")
  },
  {
    name: "ai___ko",
    path: "/ko/ai",
    meta: aiuPxt2t5xMjKDL2Hkl0_82MEtwnC_45pjUwlbNPvqYge1sMeta || {},
    component: () => import("/app/apps/website/app/pages/ai.vue")
  },
  {
    name: "ai___pl",
    path: "/pl/ai",
    meta: aiuPxt2t5xMjKDL2Hkl0_82MEtwnC_45pjUwlbNPvqYge1sMeta || {},
    component: () => import("/app/apps/website/app/pages/ai.vue")
  },
  {
    name: "ai___pt",
    path: "/pt/ai",
    meta: aiuPxt2t5xMjKDL2Hkl0_82MEtwnC_45pjUwlbNPvqYge1sMeta || {},
    component: () => import("/app/apps/website/app/pages/ai.vue")
  },
  {
    name: "ai___ru",
    path: "/ru/ai",
    meta: aiuPxt2t5xMjKDL2Hkl0_82MEtwnC_45pjUwlbNPvqYge1sMeta || {},
    component: () => import("/app/apps/website/app/pages/ai.vue")
  },
  {
    name: "ai___tr",
    path: "/tr/ai",
    meta: aiuPxt2t5xMjKDL2Hkl0_82MEtwnC_45pjUwlbNPvqYge1sMeta || {},
    component: () => import("/app/apps/website/app/pages/ai.vue")
  },
  {
    name: "ai___uk",
    path: "/uk/ai",
    meta: aiuPxt2t5xMjKDL2Hkl0_82MEtwnC_45pjUwlbNPvqYge1sMeta || {},
    component: () => import("/app/apps/website/app/pages/ai.vue")
  },
  {
    name: "ai___zh",
    path: "/zh/ai",
    meta: aiuPxt2t5xMjKDL2Hkl0_82MEtwnC_45pjUwlbNPvqYge1sMeta || {},
    component: () => import("/app/apps/website/app/pages/ai.vue")
  },
  {
    name: "app___de",
    path: "/de/app",
    meta: app0_FBI_45mBZ3hro5E_OwedZAu2ovJp4GzQLC9uaLootdQMeta || {},
    component: () => import("/app/apps/website/app/pages/app.vue")
  },
  {
    name: "app___en",
    path: "/app",
    meta: app0_FBI_45mBZ3hro5E_OwedZAu2ovJp4GzQLC9uaLootdQMeta || {},
    component: () => import("/app/apps/website/app/pages/app.vue")
  },
  {
    name: "app___es",
    path: "/es/app",
    meta: app0_FBI_45mBZ3hro5E_OwedZAu2ovJp4GzQLC9uaLootdQMeta || {},
    component: () => import("/app/apps/website/app/pages/app.vue")
  },
  {
    name: "app___fr",
    path: "/fr/app",
    meta: app0_FBI_45mBZ3hro5E_OwedZAu2ovJp4GzQLC9uaLootdQMeta || {},
    component: () => import("/app/apps/website/app/pages/app.vue")
  },
  {
    name: "app___id",
    path: "/id/app",
    meta: app0_FBI_45mBZ3hro5E_OwedZAu2ovJp4GzQLC9uaLootdQMeta || {},
    component: () => import("/app/apps/website/app/pages/app.vue")
  },
  {
    name: "app___it",
    path: "/it/app",
    meta: app0_FBI_45mBZ3hro5E_OwedZAu2ovJp4GzQLC9uaLootdQMeta || {},
    component: () => import("/app/apps/website/app/pages/app.vue")
  },
  {
    name: "app___ja",
    path: "/ja/app",
    meta: app0_FBI_45mBZ3hro5E_OwedZAu2ovJp4GzQLC9uaLootdQMeta || {},
    component: () => import("/app/apps/website/app/pages/app.vue")
  },
  {
    name: "app___ko",
    path: "/ko/app",
    meta: app0_FBI_45mBZ3hro5E_OwedZAu2ovJp4GzQLC9uaLootdQMeta || {},
    component: () => import("/app/apps/website/app/pages/app.vue")
  },
  {
    name: "app___pl",
    path: "/pl/app",
    meta: app0_FBI_45mBZ3hro5E_OwedZAu2ovJp4GzQLC9uaLootdQMeta || {},
    component: () => import("/app/apps/website/app/pages/app.vue")
  },
  {
    name: "app___pt",
    path: "/pt/app",
    meta: app0_FBI_45mBZ3hro5E_OwedZAu2ovJp4GzQLC9uaLootdQMeta || {},
    component: () => import("/app/apps/website/app/pages/app.vue")
  },
  {
    name: "app___ru",
    path: "/ru/app",
    meta: app0_FBI_45mBZ3hro5E_OwedZAu2ovJp4GzQLC9uaLootdQMeta || {},
    component: () => import("/app/apps/website/app/pages/app.vue")
  },
  {
    name: "app___tr",
    path: "/tr/app",
    meta: app0_FBI_45mBZ3hro5E_OwedZAu2ovJp4GzQLC9uaLootdQMeta || {},
    component: () => import("/app/apps/website/app/pages/app.vue")
  },
  {
    name: "app___uk",
    path: "/uk/app",
    meta: app0_FBI_45mBZ3hro5E_OwedZAu2ovJp4GzQLC9uaLootdQMeta || {},
    component: () => import("/app/apps/website/app/pages/app.vue")
  },
  {
    name: "app___zh",
    path: "/zh/app",
    meta: app0_FBI_45mBZ3hro5E_OwedZAu2ovJp4GzQLC9uaLootdQMeta || {},
    component: () => import("/app/apps/website/app/pages/app.vue")
  },
  {
    name: "nft___de",
    path: "/de/nft",
    meta: nftXgXbxht52xZMd2_Hbc_PPbmv_fjr9jDgfG2LD7NljBMMeta || {},
    component: () => import("/app/apps/website/app/pages/nft.vue")
  },
  {
    name: "nft___en",
    path: "/nft",
    meta: nftXgXbxht52xZMd2_Hbc_PPbmv_fjr9jDgfG2LD7NljBMMeta || {},
    component: () => import("/app/apps/website/app/pages/nft.vue")
  },
  {
    name: "nft___es",
    path: "/es/nft",
    meta: nftXgXbxht52xZMd2_Hbc_PPbmv_fjr9jDgfG2LD7NljBMMeta || {},
    component: () => import("/app/apps/website/app/pages/nft.vue")
  },
  {
    name: "nft___fr",
    path: "/fr/nft",
    meta: nftXgXbxht52xZMd2_Hbc_PPbmv_fjr9jDgfG2LD7NljBMMeta || {},
    component: () => import("/app/apps/website/app/pages/nft.vue")
  },
  {
    name: "nft___id",
    path: "/id/nft",
    meta: nftXgXbxht52xZMd2_Hbc_PPbmv_fjr9jDgfG2LD7NljBMMeta || {},
    component: () => import("/app/apps/website/app/pages/nft.vue")
  },
  {
    name: "nft___it",
    path: "/it/nft",
    meta: nftXgXbxht52xZMd2_Hbc_PPbmv_fjr9jDgfG2LD7NljBMMeta || {},
    component: () => import("/app/apps/website/app/pages/nft.vue")
  },
  {
    name: "nft___ja",
    path: "/ja/nft",
    meta: nftXgXbxht52xZMd2_Hbc_PPbmv_fjr9jDgfG2LD7NljBMMeta || {},
    component: () => import("/app/apps/website/app/pages/nft.vue")
  },
  {
    name: "nft___ko",
    path: "/ko/nft",
    meta: nftXgXbxht52xZMd2_Hbc_PPbmv_fjr9jDgfG2LD7NljBMMeta || {},
    component: () => import("/app/apps/website/app/pages/nft.vue")
  },
  {
    name: "nft___pl",
    path: "/pl/nft",
    meta: nftXgXbxht52xZMd2_Hbc_PPbmv_fjr9jDgfG2LD7NljBMMeta || {},
    component: () => import("/app/apps/website/app/pages/nft.vue")
  },
  {
    name: "nft___pt",
    path: "/pt/nft",
    meta: nftXgXbxht52xZMd2_Hbc_PPbmv_fjr9jDgfG2LD7NljBMMeta || {},
    component: () => import("/app/apps/website/app/pages/nft.vue")
  },
  {
    name: "nft___ru",
    path: "/ru/nft",
    meta: nftXgXbxht52xZMd2_Hbc_PPbmv_fjr9jDgfG2LD7NljBMMeta || {},
    component: () => import("/app/apps/website/app/pages/nft.vue")
  },
  {
    name: "nft___tr",
    path: "/tr/nft",
    meta: nftXgXbxht52xZMd2_Hbc_PPbmv_fjr9jDgfG2LD7NljBMMeta || {},
    component: () => import("/app/apps/website/app/pages/nft.vue")
  },
  {
    name: "nft___uk",
    path: "/uk/nft",
    meta: nftXgXbxht52xZMd2_Hbc_PPbmv_fjr9jDgfG2LD7NljBMMeta || {},
    component: () => import("/app/apps/website/app/pages/nft.vue")
  },
  {
    name: "nft___zh",
    path: "/zh/nft",
    meta: nftXgXbxht52xZMd2_Hbc_PPbmv_fjr9jDgfG2LD7NljBMMeta || {},
    component: () => import("/app/apps/website/app/pages/nft.vue")
  },
  {
    name: "index___de",
    path: "/de",
    meta: indexEZbcNnAsSV0_456BZyWWBnObQXHLJd_45R7kYAs9U5oVmNAMeta || {},
    component: () => import("/app/apps/website/app/pages/index.vue")
  },
  {
    name: "index___en",
    path: "/",
    meta: indexEZbcNnAsSV0_456BZyWWBnObQXHLJd_45R7kYAs9U5oVmNAMeta || {},
    component: () => import("/app/apps/website/app/pages/index.vue")
  },
  {
    name: "index___es",
    path: "/es",
    meta: indexEZbcNnAsSV0_456BZyWWBnObQXHLJd_45R7kYAs9U5oVmNAMeta || {},
    component: () => import("/app/apps/website/app/pages/index.vue")
  },
  {
    name: "index___fr",
    path: "/fr",
    meta: indexEZbcNnAsSV0_456BZyWWBnObQXHLJd_45R7kYAs9U5oVmNAMeta || {},
    component: () => import("/app/apps/website/app/pages/index.vue")
  },
  {
    name: "index___id",
    path: "/id",
    meta: indexEZbcNnAsSV0_456BZyWWBnObQXHLJd_45R7kYAs9U5oVmNAMeta || {},
    component: () => import("/app/apps/website/app/pages/index.vue")
  },
  {
    name: "index___it",
    path: "/it",
    meta: indexEZbcNnAsSV0_456BZyWWBnObQXHLJd_45R7kYAs9U5oVmNAMeta || {},
    component: () => import("/app/apps/website/app/pages/index.vue")
  },
  {
    name: "index___ja",
    path: "/ja",
    meta: indexEZbcNnAsSV0_456BZyWWBnObQXHLJd_45R7kYAs9U5oVmNAMeta || {},
    component: () => import("/app/apps/website/app/pages/index.vue")
  },
  {
    name: "index___ko",
    path: "/ko",
    meta: indexEZbcNnAsSV0_456BZyWWBnObQXHLJd_45R7kYAs9U5oVmNAMeta || {},
    component: () => import("/app/apps/website/app/pages/index.vue")
  },
  {
    name: "index___pl",
    path: "/pl",
    meta: indexEZbcNnAsSV0_456BZyWWBnObQXHLJd_45R7kYAs9U5oVmNAMeta || {},
    component: () => import("/app/apps/website/app/pages/index.vue")
  },
  {
    name: "index___pt",
    path: "/pt",
    meta: indexEZbcNnAsSV0_456BZyWWBnObQXHLJd_45R7kYAs9U5oVmNAMeta || {},
    component: () => import("/app/apps/website/app/pages/index.vue")
  },
  {
    name: "index___ru",
    path: "/ru",
    meta: indexEZbcNnAsSV0_456BZyWWBnObQXHLJd_45R7kYAs9U5oVmNAMeta || {},
    component: () => import("/app/apps/website/app/pages/index.vue")
  },
  {
    name: "index___tr",
    path: "/tr",
    meta: indexEZbcNnAsSV0_456BZyWWBnObQXHLJd_45R7kYAs9U5oVmNAMeta || {},
    component: () => import("/app/apps/website/app/pages/index.vue")
  },
  {
    name: "index___uk",
    path: "/uk",
    meta: indexEZbcNnAsSV0_456BZyWWBnObQXHLJd_45R7kYAs9U5oVmNAMeta || {},
    component: () => import("/app/apps/website/app/pages/index.vue")
  },
  {
    name: "index___zh",
    path: "/zh",
    meta: indexEZbcNnAsSV0_456BZyWWBnObQXHLJd_45R7kYAs9U5oVmNAMeta || {},
    component: () => import("/app/apps/website/app/pages/index.vue")
  },
  {
    name: "terms___de",
    path: "/de/terms",
    meta: termsgEecWgorFGdsJsnV4hEuqTrqVim0teDfl1KpeFgbUg8Meta || {},
    component: () => import("/app/apps/website/app/pages/terms.vue")
  },
  {
    name: "terms___en",
    path: "/terms",
    meta: termsgEecWgorFGdsJsnV4hEuqTrqVim0teDfl1KpeFgbUg8Meta || {},
    component: () => import("/app/apps/website/app/pages/terms.vue")
  },
  {
    name: "terms___es",
    path: "/es/terms",
    meta: termsgEecWgorFGdsJsnV4hEuqTrqVim0teDfl1KpeFgbUg8Meta || {},
    component: () => import("/app/apps/website/app/pages/terms.vue")
  },
  {
    name: "terms___fr",
    path: "/fr/terms",
    meta: termsgEecWgorFGdsJsnV4hEuqTrqVim0teDfl1KpeFgbUg8Meta || {},
    component: () => import("/app/apps/website/app/pages/terms.vue")
  },
  {
    name: "terms___id",
    path: "/id/terms",
    meta: termsgEecWgorFGdsJsnV4hEuqTrqVim0teDfl1KpeFgbUg8Meta || {},
    component: () => import("/app/apps/website/app/pages/terms.vue")
  },
  {
    name: "terms___it",
    path: "/it/terms",
    meta: termsgEecWgorFGdsJsnV4hEuqTrqVim0teDfl1KpeFgbUg8Meta || {},
    component: () => import("/app/apps/website/app/pages/terms.vue")
  },
  {
    name: "terms___ja",
    path: "/ja/terms",
    meta: termsgEecWgorFGdsJsnV4hEuqTrqVim0teDfl1KpeFgbUg8Meta || {},
    component: () => import("/app/apps/website/app/pages/terms.vue")
  },
  {
    name: "terms___ko",
    path: "/ko/terms",
    meta: termsgEecWgorFGdsJsnV4hEuqTrqVim0teDfl1KpeFgbUg8Meta || {},
    component: () => import("/app/apps/website/app/pages/terms.vue")
  },
  {
    name: "terms___pl",
    path: "/pl/terms",
    meta: termsgEecWgorFGdsJsnV4hEuqTrqVim0teDfl1KpeFgbUg8Meta || {},
    component: () => import("/app/apps/website/app/pages/terms.vue")
  },
  {
    name: "terms___pt",
    path: "/pt/terms",
    meta: termsgEecWgorFGdsJsnV4hEuqTrqVim0teDfl1KpeFgbUg8Meta || {},
    component: () => import("/app/apps/website/app/pages/terms.vue")
  },
  {
    name: "terms___ru",
    path: "/ru/terms",
    meta: termsgEecWgorFGdsJsnV4hEuqTrqVim0teDfl1KpeFgbUg8Meta || {},
    component: () => import("/app/apps/website/app/pages/terms.vue")
  },
  {
    name: "terms___tr",
    path: "/tr/terms",
    meta: termsgEecWgorFGdsJsnV4hEuqTrqVim0teDfl1KpeFgbUg8Meta || {},
    component: () => import("/app/apps/website/app/pages/terms.vue")
  },
  {
    name: "terms___uk",
    path: "/uk/terms",
    meta: termsgEecWgorFGdsJsnV4hEuqTrqVim0teDfl1KpeFgbUg8Meta || {},
    component: () => import("/app/apps/website/app/pages/terms.vue")
  },
  {
    name: "terms___zh",
    path: "/zh/terms",
    meta: termsgEecWgorFGdsJsnV4hEuqTrqVim0teDfl1KpeFgbUg8Meta || {},
    component: () => import("/app/apps/website/app/pages/terms.vue")
  },
  {
    name: "token___de",
    path: "/de/token",
    meta: tokenE4SBM_tw6_m7FlEQzH8R6DwcHM_8FyN_45KOi_HWRhKscMeta || {},
    component: () => import("/app/apps/website/app/pages/token.vue")
  },
  {
    name: "token___en",
    path: "/token",
    meta: tokenE4SBM_tw6_m7FlEQzH8R6DwcHM_8FyN_45KOi_HWRhKscMeta || {},
    component: () => import("/app/apps/website/app/pages/token.vue")
  },
  {
    name: "token___es",
    path: "/es/token",
    meta: tokenE4SBM_tw6_m7FlEQzH8R6DwcHM_8FyN_45KOi_HWRhKscMeta || {},
    component: () => import("/app/apps/website/app/pages/token.vue")
  },
  {
    name: "token___fr",
    path: "/fr/token",
    meta: tokenE4SBM_tw6_m7FlEQzH8R6DwcHM_8FyN_45KOi_HWRhKscMeta || {},
    component: () => import("/app/apps/website/app/pages/token.vue")
  },
  {
    name: "token___id",
    path: "/id/token",
    meta: tokenE4SBM_tw6_m7FlEQzH8R6DwcHM_8FyN_45KOi_HWRhKscMeta || {},
    component: () => import("/app/apps/website/app/pages/token.vue")
  },
  {
    name: "token___it",
    path: "/it/token",
    meta: tokenE4SBM_tw6_m7FlEQzH8R6DwcHM_8FyN_45KOi_HWRhKscMeta || {},
    component: () => import("/app/apps/website/app/pages/token.vue")
  },
  {
    name: "token___ja",
    path: "/ja/token",
    meta: tokenE4SBM_tw6_m7FlEQzH8R6DwcHM_8FyN_45KOi_HWRhKscMeta || {},
    component: () => import("/app/apps/website/app/pages/token.vue")
  },
  {
    name: "token___ko",
    path: "/ko/token",
    meta: tokenE4SBM_tw6_m7FlEQzH8R6DwcHM_8FyN_45KOi_HWRhKscMeta || {},
    component: () => import("/app/apps/website/app/pages/token.vue")
  },
  {
    name: "token___pl",
    path: "/pl/token",
    meta: tokenE4SBM_tw6_m7FlEQzH8R6DwcHM_8FyN_45KOi_HWRhKscMeta || {},
    component: () => import("/app/apps/website/app/pages/token.vue")
  },
  {
    name: "token___pt",
    path: "/pt/token",
    meta: tokenE4SBM_tw6_m7FlEQzH8R6DwcHM_8FyN_45KOi_HWRhKscMeta || {},
    component: () => import("/app/apps/website/app/pages/token.vue")
  },
  {
    name: "token___ru",
    path: "/ru/token",
    meta: tokenE4SBM_tw6_m7FlEQzH8R6DwcHM_8FyN_45KOi_HWRhKscMeta || {},
    component: () => import("/app/apps/website/app/pages/token.vue")
  },
  {
    name: "token___tr",
    path: "/tr/token",
    meta: tokenE4SBM_tw6_m7FlEQzH8R6DwcHM_8FyN_45KOi_HWRhKscMeta || {},
    component: () => import("/app/apps/website/app/pages/token.vue")
  },
  {
    name: "token___uk",
    path: "/uk/token",
    meta: tokenE4SBM_tw6_m7FlEQzH8R6DwcHM_8FyN_45KOi_HWRhKscMeta || {},
    component: () => import("/app/apps/website/app/pages/token.vue")
  },
  {
    name: "token___zh",
    path: "/zh/token",
    meta: tokenE4SBM_tw6_m7FlEQzH8R6DwcHM_8FyN_45KOi_HWRhKscMeta || {},
    component: () => import("/app/apps/website/app/pages/token.vue")
  },
  {
    name: "gaming___de",
    path: "/de/gaming",
    meta: gamingJEMPRDYiYyDb0RBnS7QpdH8XYfWK_45rYqp50YX5Pj_p0Meta || {},
    component: () => import("/app/apps/website/app/pages/gaming.vue")
  },
  {
    name: "gaming___en",
    path: "/gaming",
    meta: gamingJEMPRDYiYyDb0RBnS7QpdH8XYfWK_45rYqp50YX5Pj_p0Meta || {},
    component: () => import("/app/apps/website/app/pages/gaming.vue")
  },
  {
    name: "gaming___es",
    path: "/es/gaming",
    meta: gamingJEMPRDYiYyDb0RBnS7QpdH8XYfWK_45rYqp50YX5Pj_p0Meta || {},
    component: () => import("/app/apps/website/app/pages/gaming.vue")
  },
  {
    name: "gaming___fr",
    path: "/fr/gaming",
    meta: gamingJEMPRDYiYyDb0RBnS7QpdH8XYfWK_45rYqp50YX5Pj_p0Meta || {},
    component: () => import("/app/apps/website/app/pages/gaming.vue")
  },
  {
    name: "gaming___id",
    path: "/id/gaming",
    meta: gamingJEMPRDYiYyDb0RBnS7QpdH8XYfWK_45rYqp50YX5Pj_p0Meta || {},
    component: () => import("/app/apps/website/app/pages/gaming.vue")
  },
  {
    name: "gaming___it",
    path: "/it/gaming",
    meta: gamingJEMPRDYiYyDb0RBnS7QpdH8XYfWK_45rYqp50YX5Pj_p0Meta || {},
    component: () => import("/app/apps/website/app/pages/gaming.vue")
  },
  {
    name: "gaming___ja",
    path: "/ja/gaming",
    meta: gamingJEMPRDYiYyDb0RBnS7QpdH8XYfWK_45rYqp50YX5Pj_p0Meta || {},
    component: () => import("/app/apps/website/app/pages/gaming.vue")
  },
  {
    name: "gaming___ko",
    path: "/ko/gaming",
    meta: gamingJEMPRDYiYyDb0RBnS7QpdH8XYfWK_45rYqp50YX5Pj_p0Meta || {},
    component: () => import("/app/apps/website/app/pages/gaming.vue")
  },
  {
    name: "gaming___pl",
    path: "/pl/gaming",
    meta: gamingJEMPRDYiYyDb0RBnS7QpdH8XYfWK_45rYqp50YX5Pj_p0Meta || {},
    component: () => import("/app/apps/website/app/pages/gaming.vue")
  },
  {
    name: "gaming___pt",
    path: "/pt/gaming",
    meta: gamingJEMPRDYiYyDb0RBnS7QpdH8XYfWK_45rYqp50YX5Pj_p0Meta || {},
    component: () => import("/app/apps/website/app/pages/gaming.vue")
  },
  {
    name: "gaming___ru",
    path: "/ru/gaming",
    meta: gamingJEMPRDYiYyDb0RBnS7QpdH8XYfWK_45rYqp50YX5Pj_p0Meta || {},
    component: () => import("/app/apps/website/app/pages/gaming.vue")
  },
  {
    name: "gaming___tr",
    path: "/tr/gaming",
    meta: gamingJEMPRDYiYyDb0RBnS7QpdH8XYfWK_45rYqp50YX5Pj_p0Meta || {},
    component: () => import("/app/apps/website/app/pages/gaming.vue")
  },
  {
    name: "gaming___uk",
    path: "/uk/gaming",
    meta: gamingJEMPRDYiYyDb0RBnS7QpdH8XYfWK_45rYqp50YX5Pj_p0Meta || {},
    component: () => import("/app/apps/website/app/pages/gaming.vue")
  },
  {
    name: "gaming___zh",
    path: "/zh/gaming",
    meta: gamingJEMPRDYiYyDb0RBnS7QpdH8XYfWK_45rYqp50YX5Pj_p0Meta || {},
    component: () => import("/app/apps/website/app/pages/gaming.vue")
  },
  {
    name: "privacy___de",
    path: "/de/privacy",
    meta: privacyxfEsP4J1o_45iMaHK0ziLjI1Asj0m2ofcOVyPU9xYbllAMeta || {},
    component: () => import("/app/apps/website/app/pages/privacy.vue")
  },
  {
    name: "privacy___en",
    path: "/privacy",
    meta: privacyxfEsP4J1o_45iMaHK0ziLjI1Asj0m2ofcOVyPU9xYbllAMeta || {},
    component: () => import("/app/apps/website/app/pages/privacy.vue")
  },
  {
    name: "privacy___es",
    path: "/es/privacy",
    meta: privacyxfEsP4J1o_45iMaHK0ziLjI1Asj0m2ofcOVyPU9xYbllAMeta || {},
    component: () => import("/app/apps/website/app/pages/privacy.vue")
  },
  {
    name: "privacy___fr",
    path: "/fr/privacy",
    meta: privacyxfEsP4J1o_45iMaHK0ziLjI1Asj0m2ofcOVyPU9xYbllAMeta || {},
    component: () => import("/app/apps/website/app/pages/privacy.vue")
  },
  {
    name: "privacy___id",
    path: "/id/privacy",
    meta: privacyxfEsP4J1o_45iMaHK0ziLjI1Asj0m2ofcOVyPU9xYbllAMeta || {},
    component: () => import("/app/apps/website/app/pages/privacy.vue")
  },
  {
    name: "privacy___it",
    path: "/it/privacy",
    meta: privacyxfEsP4J1o_45iMaHK0ziLjI1Asj0m2ofcOVyPU9xYbllAMeta || {},
    component: () => import("/app/apps/website/app/pages/privacy.vue")
  },
  {
    name: "privacy___ja",
    path: "/ja/privacy",
    meta: privacyxfEsP4J1o_45iMaHK0ziLjI1Asj0m2ofcOVyPU9xYbllAMeta || {},
    component: () => import("/app/apps/website/app/pages/privacy.vue")
  },
  {
    name: "privacy___ko",
    path: "/ko/privacy",
    meta: privacyxfEsP4J1o_45iMaHK0ziLjI1Asj0m2ofcOVyPU9xYbllAMeta || {},
    component: () => import("/app/apps/website/app/pages/privacy.vue")
  },
  {
    name: "privacy___pl",
    path: "/pl/privacy",
    meta: privacyxfEsP4J1o_45iMaHK0ziLjI1Asj0m2ofcOVyPU9xYbllAMeta || {},
    component: () => import("/app/apps/website/app/pages/privacy.vue")
  },
  {
    name: "privacy___pt",
    path: "/pt/privacy",
    meta: privacyxfEsP4J1o_45iMaHK0ziLjI1Asj0m2ofcOVyPU9xYbllAMeta || {},
    component: () => import("/app/apps/website/app/pages/privacy.vue")
  },
  {
    name: "privacy___ru",
    path: "/ru/privacy",
    meta: privacyxfEsP4J1o_45iMaHK0ziLjI1Asj0m2ofcOVyPU9xYbllAMeta || {},
    component: () => import("/app/apps/website/app/pages/privacy.vue")
  },
  {
    name: "privacy___tr",
    path: "/tr/privacy",
    meta: privacyxfEsP4J1o_45iMaHK0ziLjI1Asj0m2ofcOVyPU9xYbllAMeta || {},
    component: () => import("/app/apps/website/app/pages/privacy.vue")
  },
  {
    name: "privacy___uk",
    path: "/uk/privacy",
    meta: privacyxfEsP4J1o_45iMaHK0ziLjI1Asj0m2ofcOVyPU9xYbllAMeta || {},
    component: () => import("/app/apps/website/app/pages/privacy.vue")
  },
  {
    name: "privacy___zh",
    path: "/zh/privacy",
    meta: privacyxfEsP4J1o_45iMaHK0ziLjI1Asj0m2ofcOVyPU9xYbllAMeta || {},
    component: () => import("/app/apps/website/app/pages/privacy.vue")
  },
  {
    name: "download___de",
    path: "/de/download",
    component: () => import("/app/apps/website/app/pages/download.vue")
  },
  {
    name: "download___en",
    path: "/download",
    component: () => import("/app/apps/website/app/pages/download.vue")
  },
  {
    name: "download___es",
    path: "/es/download",
    component: () => import("/app/apps/website/app/pages/download.vue")
  },
  {
    name: "download___fr",
    path: "/fr/download",
    component: () => import("/app/apps/website/app/pages/download.vue")
  },
  {
    name: "download___id",
    path: "/id/download",
    component: () => import("/app/apps/website/app/pages/download.vue")
  },
  {
    name: "download___it",
    path: "/it/download",
    component: () => import("/app/apps/website/app/pages/download.vue")
  },
  {
    name: "download___ja",
    path: "/ja/download",
    component: () => import("/app/apps/website/app/pages/download.vue")
  },
  {
    name: "download___ko",
    path: "/ko/download",
    component: () => import("/app/apps/website/app/pages/download.vue")
  },
  {
    name: "download___pl",
    path: "/pl/download",
    component: () => import("/app/apps/website/app/pages/download.vue")
  },
  {
    name: "download___pt",
    path: "/pt/download",
    component: () => import("/app/apps/website/app/pages/download.vue")
  },
  {
    name: "download___ru",
    path: "/ru/download",
    component: () => import("/app/apps/website/app/pages/download.vue")
  },
  {
    name: "download___tr",
    path: "/tr/download",
    component: () => import("/app/apps/website/app/pages/download.vue")
  },
  {
    name: "download___uk",
    path: "/uk/download",
    component: () => import("/app/apps/website/app/pages/download.vue")
  },
  {
    name: "download___zh",
    path: "/zh/download",
    component: () => import("/app/apps/website/app/pages/download.vue")
  },
  {
    name: "blog___de",
    path: "/de/blog",
    meta: indexVTQH5xDgF8hb7Lnk_452SkTtujt5OHnhyEDqRTwKdOy2IMeta || {},
    component: () => import("/app/apps/website/app/pages/blog/index.vue")
  },
  {
    name: "blog___en",
    path: "/blog",
    meta: indexVTQH5xDgF8hb7Lnk_452SkTtujt5OHnhyEDqRTwKdOy2IMeta || {},
    component: () => import("/app/apps/website/app/pages/blog/index.vue")
  },
  {
    name: "blog___es",
    path: "/es/blog",
    meta: indexVTQH5xDgF8hb7Lnk_452SkTtujt5OHnhyEDqRTwKdOy2IMeta || {},
    component: () => import("/app/apps/website/app/pages/blog/index.vue")
  },
  {
    name: "blog___fr",
    path: "/fr/blog",
    meta: indexVTQH5xDgF8hb7Lnk_452SkTtujt5OHnhyEDqRTwKdOy2IMeta || {},
    component: () => import("/app/apps/website/app/pages/blog/index.vue")
  },
  {
    name: "blog___id",
    path: "/id/blog",
    meta: indexVTQH5xDgF8hb7Lnk_452SkTtujt5OHnhyEDqRTwKdOy2IMeta || {},
    component: () => import("/app/apps/website/app/pages/blog/index.vue")
  },
  {
    name: "blog___it",
    path: "/it/blog",
    meta: indexVTQH5xDgF8hb7Lnk_452SkTtujt5OHnhyEDqRTwKdOy2IMeta || {},
    component: () => import("/app/apps/website/app/pages/blog/index.vue")
  },
  {
    name: "blog___ja",
    path: "/ja/blog",
    meta: indexVTQH5xDgF8hb7Lnk_452SkTtujt5OHnhyEDqRTwKdOy2IMeta || {},
    component: () => import("/app/apps/website/app/pages/blog/index.vue")
  },
  {
    name: "blog___ko",
    path: "/ko/blog",
    meta: indexVTQH5xDgF8hb7Lnk_452SkTtujt5OHnhyEDqRTwKdOy2IMeta || {},
    component: () => import("/app/apps/website/app/pages/blog/index.vue")
  },
  {
    name: "blog___pl",
    path: "/pl/blog",
    meta: indexVTQH5xDgF8hb7Lnk_452SkTtujt5OHnhyEDqRTwKdOy2IMeta || {},
    component: () => import("/app/apps/website/app/pages/blog/index.vue")
  },
  {
    name: "blog___pt",
    path: "/pt/blog",
    meta: indexVTQH5xDgF8hb7Lnk_452SkTtujt5OHnhyEDqRTwKdOy2IMeta || {},
    component: () => import("/app/apps/website/app/pages/blog/index.vue")
  },
  {
    name: "blog___ru",
    path: "/ru/blog",
    meta: indexVTQH5xDgF8hb7Lnk_452SkTtujt5OHnhyEDqRTwKdOy2IMeta || {},
    component: () => import("/app/apps/website/app/pages/blog/index.vue")
  },
  {
    name: "blog___tr",
    path: "/tr/blog",
    meta: indexVTQH5xDgF8hb7Lnk_452SkTtujt5OHnhyEDqRTwKdOy2IMeta || {},
    component: () => import("/app/apps/website/app/pages/blog/index.vue")
  },
  {
    name: "blog___uk",
    path: "/uk/blog",
    meta: indexVTQH5xDgF8hb7Lnk_452SkTtujt5OHnhyEDqRTwKdOy2IMeta || {},
    component: () => import("/app/apps/website/app/pages/blog/index.vue")
  },
  {
    name: "blog___zh",
    path: "/zh/blog",
    meta: indexVTQH5xDgF8hb7Lnk_452SkTtujt5OHnhyEDqRTwKdOy2IMeta || {},
    component: () => import("/app/apps/website/app/pages/blog/index.vue")
  },
  {
    name: "debug-icon___de",
    path: "/de/debug/icon",
    component: () => import("/app/apps/website/app/pages/debug/icon.vue")
  },
  {
    name: "debug-icon___en",
    path: "/debug/icon",
    component: () => import("/app/apps/website/app/pages/debug/icon.vue")
  },
  {
    name: "debug-icon___es",
    path: "/es/debug/icon",
    component: () => import("/app/apps/website/app/pages/debug/icon.vue")
  },
  {
    name: "debug-icon___fr",
    path: "/fr/debug/icon",
    component: () => import("/app/apps/website/app/pages/debug/icon.vue")
  },
  {
    name: "debug-icon___id",
    path: "/id/debug/icon",
    component: () => import("/app/apps/website/app/pages/debug/icon.vue")
  },
  {
    name: "debug-icon___it",
    path: "/it/debug/icon",
    component: () => import("/app/apps/website/app/pages/debug/icon.vue")
  },
  {
    name: "debug-icon___ja",
    path: "/ja/debug/icon",
    component: () => import("/app/apps/website/app/pages/debug/icon.vue")
  },
  {
    name: "debug-icon___ko",
    path: "/ko/debug/icon",
    component: () => import("/app/apps/website/app/pages/debug/icon.vue")
  },
  {
    name: "debug-icon___pl",
    path: "/pl/debug/icon",
    component: () => import("/app/apps/website/app/pages/debug/icon.vue")
  },
  {
    name: "debug-icon___pt",
    path: "/pt/debug/icon",
    component: () => import("/app/apps/website/app/pages/debug/icon.vue")
  },
  {
    name: "debug-icon___ru",
    path: "/ru/debug/icon",
    component: () => import("/app/apps/website/app/pages/debug/icon.vue")
  },
  {
    name: "debug-icon___tr",
    path: "/tr/debug/icon",
    component: () => import("/app/apps/website/app/pages/debug/icon.vue")
  },
  {
    name: "debug-icon___uk",
    path: "/uk/debug/icon",
    component: () => import("/app/apps/website/app/pages/debug/icon.vue")
  },
  {
    name: "debug-icon___zh",
    path: "/zh/debug/icon",
    component: () => import("/app/apps/website/app/pages/debug/icon.vue")
  },
  {
    name: "hubs___de",
    path: "/de/hubs",
    meta: indexuAOAK4rbVv6BTcwJWW6N2gaJ3lTPGYxQm8RCYO2CkbwMeta || {},
    component: () => import("/app/apps/website/app/pages/hubs/index.vue")
  },
  {
    name: "hubs___en",
    path: "/hubs",
    meta: indexuAOAK4rbVv6BTcwJWW6N2gaJ3lTPGYxQm8RCYO2CkbwMeta || {},
    component: () => import("/app/apps/website/app/pages/hubs/index.vue")
  },
  {
    name: "hubs___es",
    path: "/es/hubs",
    meta: indexuAOAK4rbVv6BTcwJWW6N2gaJ3lTPGYxQm8RCYO2CkbwMeta || {},
    component: () => import("/app/apps/website/app/pages/hubs/index.vue")
  },
  {
    name: "hubs___fr",
    path: "/fr/hubs",
    meta: indexuAOAK4rbVv6BTcwJWW6N2gaJ3lTPGYxQm8RCYO2CkbwMeta || {},
    component: () => import("/app/apps/website/app/pages/hubs/index.vue")
  },
  {
    name: "hubs___id",
    path: "/id/hubs",
    meta: indexuAOAK4rbVv6BTcwJWW6N2gaJ3lTPGYxQm8RCYO2CkbwMeta || {},
    component: () => import("/app/apps/website/app/pages/hubs/index.vue")
  },
  {
    name: "hubs___it",
    path: "/it/hubs",
    meta: indexuAOAK4rbVv6BTcwJWW6N2gaJ3lTPGYxQm8RCYO2CkbwMeta || {},
    component: () => import("/app/apps/website/app/pages/hubs/index.vue")
  },
  {
    name: "hubs___ja",
    path: "/ja/hubs",
    meta: indexuAOAK4rbVv6BTcwJWW6N2gaJ3lTPGYxQm8RCYO2CkbwMeta || {},
    component: () => import("/app/apps/website/app/pages/hubs/index.vue")
  },
  {
    name: "hubs___ko",
    path: "/ko/hubs",
    meta: indexuAOAK4rbVv6BTcwJWW6N2gaJ3lTPGYxQm8RCYO2CkbwMeta || {},
    component: () => import("/app/apps/website/app/pages/hubs/index.vue")
  },
  {
    name: "hubs___pl",
    path: "/pl/hubs",
    meta: indexuAOAK4rbVv6BTcwJWW6N2gaJ3lTPGYxQm8RCYO2CkbwMeta || {},
    component: () => import("/app/apps/website/app/pages/hubs/index.vue")
  },
  {
    name: "hubs___pt",
    path: "/pt/hubs",
    meta: indexuAOAK4rbVv6BTcwJWW6N2gaJ3lTPGYxQm8RCYO2CkbwMeta || {},
    component: () => import("/app/apps/website/app/pages/hubs/index.vue")
  },
  {
    name: "hubs___ru",
    path: "/ru/hubs",
    meta: indexuAOAK4rbVv6BTcwJWW6N2gaJ3lTPGYxQm8RCYO2CkbwMeta || {},
    component: () => import("/app/apps/website/app/pages/hubs/index.vue")
  },
  {
    name: "hubs___tr",
    path: "/tr/hubs",
    meta: indexuAOAK4rbVv6BTcwJWW6N2gaJ3lTPGYxQm8RCYO2CkbwMeta || {},
    component: () => import("/app/apps/website/app/pages/hubs/index.vue")
  },
  {
    name: "hubs___uk",
    path: "/uk/hubs",
    meta: indexuAOAK4rbVv6BTcwJWW6N2gaJ3lTPGYxQm8RCYO2CkbwMeta || {},
    component: () => import("/app/apps/website/app/pages/hubs/index.vue")
  },
  {
    name: "hubs___zh",
    path: "/zh/hubs",
    meta: indexuAOAK4rbVv6BTcwJWW6N2gaJ3lTPGYxQm8RCYO2CkbwMeta || {},
    component: () => import("/app/apps/website/app/pages/hubs/index.vue")
  },
  {
    name: "debug___de",
    path: "/de/debug",
    component: () => import("/app/apps/website/app/pages/debug/index.vue")
  },
  {
    name: "debug___en",
    path: "/debug",
    component: () => import("/app/apps/website/app/pages/debug/index.vue")
  },
  {
    name: "debug___es",
    path: "/es/debug",
    component: () => import("/app/apps/website/app/pages/debug/index.vue")
  },
  {
    name: "debug___fr",
    path: "/fr/debug",
    component: () => import("/app/apps/website/app/pages/debug/index.vue")
  },
  {
    name: "debug___id",
    path: "/id/debug",
    component: () => import("/app/apps/website/app/pages/debug/index.vue")
  },
  {
    name: "debug___it",
    path: "/it/debug",
    component: () => import("/app/apps/website/app/pages/debug/index.vue")
  },
  {
    name: "debug___ja",
    path: "/ja/debug",
    component: () => import("/app/apps/website/app/pages/debug/index.vue")
  },
  {
    name: "debug___ko",
    path: "/ko/debug",
    component: () => import("/app/apps/website/app/pages/debug/index.vue")
  },
  {
    name: "debug___pl",
    path: "/pl/debug",
    component: () => import("/app/apps/website/app/pages/debug/index.vue")
  },
  {
    name: "debug___pt",
    path: "/pt/debug",
    component: () => import("/app/apps/website/app/pages/debug/index.vue")
  },
  {
    name: "debug___ru",
    path: "/ru/debug",
    component: () => import("/app/apps/website/app/pages/debug/index.vue")
  },
  {
    name: "debug___tr",
    path: "/tr/debug",
    component: () => import("/app/apps/website/app/pages/debug/index.vue")
  },
  {
    name: "debug___uk",
    path: "/uk/debug",
    component: () => import("/app/apps/website/app/pages/debug/index.vue")
  },
  {
    name: "debug___zh",
    path: "/zh/debug",
    component: () => import("/app/apps/website/app/pages/debug/index.vue")
  },
  {
    name: "demos___de",
    path: "/de/demos",
    meta: indexhUdoXrmrmaE3NHUXASy2O2aTMZyWJ7f9SidKCu9KNQoMeta || {},
    component: () => import("/app/apps/website/app/pages/demos/index.vue")
  },
  {
    name: "demos___en",
    path: "/demos",
    meta: indexhUdoXrmrmaE3NHUXASy2O2aTMZyWJ7f9SidKCu9KNQoMeta || {},
    component: () => import("/app/apps/website/app/pages/demos/index.vue")
  },
  {
    name: "demos___es",
    path: "/es/demos",
    meta: indexhUdoXrmrmaE3NHUXASy2O2aTMZyWJ7f9SidKCu9KNQoMeta || {},
    component: () => import("/app/apps/website/app/pages/demos/index.vue")
  },
  {
    name: "demos___fr",
    path: "/fr/demos",
    meta: indexhUdoXrmrmaE3NHUXASy2O2aTMZyWJ7f9SidKCu9KNQoMeta || {},
    component: () => import("/app/apps/website/app/pages/demos/index.vue")
  },
  {
    name: "demos___id",
    path: "/id/demos",
    meta: indexhUdoXrmrmaE3NHUXASy2O2aTMZyWJ7f9SidKCu9KNQoMeta || {},
    component: () => import("/app/apps/website/app/pages/demos/index.vue")
  },
  {
    name: "demos___it",
    path: "/it/demos",
    meta: indexhUdoXrmrmaE3NHUXASy2O2aTMZyWJ7f9SidKCu9KNQoMeta || {},
    component: () => import("/app/apps/website/app/pages/demos/index.vue")
  },
  {
    name: "demos___ja",
    path: "/ja/demos",
    meta: indexhUdoXrmrmaE3NHUXASy2O2aTMZyWJ7f9SidKCu9KNQoMeta || {},
    component: () => import("/app/apps/website/app/pages/demos/index.vue")
  },
  {
    name: "demos___ko",
    path: "/ko/demos",
    meta: indexhUdoXrmrmaE3NHUXASy2O2aTMZyWJ7f9SidKCu9KNQoMeta || {},
    component: () => import("/app/apps/website/app/pages/demos/index.vue")
  },
  {
    name: "demos___pl",
    path: "/pl/demos",
    meta: indexhUdoXrmrmaE3NHUXASy2O2aTMZyWJ7f9SidKCu9KNQoMeta || {},
    component: () => import("/app/apps/website/app/pages/demos/index.vue")
  },
  {
    name: "demos___pt",
    path: "/pt/demos",
    meta: indexhUdoXrmrmaE3NHUXASy2O2aTMZyWJ7f9SidKCu9KNQoMeta || {},
    component: () => import("/app/apps/website/app/pages/demos/index.vue")
  },
  {
    name: "demos___ru",
    path: "/ru/demos",
    meta: indexhUdoXrmrmaE3NHUXASy2O2aTMZyWJ7f9SidKCu9KNQoMeta || {},
    component: () => import("/app/apps/website/app/pages/demos/index.vue")
  },
  {
    name: "demos___tr",
    path: "/tr/demos",
    meta: indexhUdoXrmrmaE3NHUXASy2O2aTMZyWJ7f9SidKCu9KNQoMeta || {},
    component: () => import("/app/apps/website/app/pages/demos/index.vue")
  },
  {
    name: "demos___uk",
    path: "/uk/demos",
    meta: indexhUdoXrmrmaE3NHUXASy2O2aTMZyWJ7f9SidKCu9KNQoMeta || {},
    component: () => import("/app/apps/website/app/pages/demos/index.vue")
  },
  {
    name: "demos___zh",
    path: "/zh/demos",
    meta: indexhUdoXrmrmaE3NHUXASy2O2aTMZyWJ7f9SidKCu9KNQoMeta || {},
    component: () => import("/app/apps/website/app/pages/demos/index.vue")
  },
  {
    name: "nft-tickets___de",
    path: "/de/nft-tickets",
    meta: nft_45ticketszcQw_45PVX1uqnYfTY0rjps5tm0eLkzhZmJqRnqG4toYcMeta || {},
    component: () => import("/app/apps/website/app/pages/nft-tickets.vue")
  },
  {
    name: "nft-tickets___en",
    path: "/nft-tickets",
    meta: nft_45ticketszcQw_45PVX1uqnYfTY0rjps5tm0eLkzhZmJqRnqG4toYcMeta || {},
    component: () => import("/app/apps/website/app/pages/nft-tickets.vue")
  },
  {
    name: "nft-tickets___es",
    path: "/es/nft-tickets",
    meta: nft_45ticketszcQw_45PVX1uqnYfTY0rjps5tm0eLkzhZmJqRnqG4toYcMeta || {},
    component: () => import("/app/apps/website/app/pages/nft-tickets.vue")
  },
  {
    name: "nft-tickets___fr",
    path: "/fr/nft-tickets",
    meta: nft_45ticketszcQw_45PVX1uqnYfTY0rjps5tm0eLkzhZmJqRnqG4toYcMeta || {},
    component: () => import("/app/apps/website/app/pages/nft-tickets.vue")
  },
  {
    name: "nft-tickets___id",
    path: "/id/nft-tickets",
    meta: nft_45ticketszcQw_45PVX1uqnYfTY0rjps5tm0eLkzhZmJqRnqG4toYcMeta || {},
    component: () => import("/app/apps/website/app/pages/nft-tickets.vue")
  },
  {
    name: "nft-tickets___it",
    path: "/it/nft-tickets",
    meta: nft_45ticketszcQw_45PVX1uqnYfTY0rjps5tm0eLkzhZmJqRnqG4toYcMeta || {},
    component: () => import("/app/apps/website/app/pages/nft-tickets.vue")
  },
  {
    name: "nft-tickets___ja",
    path: "/ja/nft-tickets",
    meta: nft_45ticketszcQw_45PVX1uqnYfTY0rjps5tm0eLkzhZmJqRnqG4toYcMeta || {},
    component: () => import("/app/apps/website/app/pages/nft-tickets.vue")
  },
  {
    name: "nft-tickets___ko",
    path: "/ko/nft-tickets",
    meta: nft_45ticketszcQw_45PVX1uqnYfTY0rjps5tm0eLkzhZmJqRnqG4toYcMeta || {},
    component: () => import("/app/apps/website/app/pages/nft-tickets.vue")
  },
  {
    name: "nft-tickets___pl",
    path: "/pl/nft-tickets",
    meta: nft_45ticketszcQw_45PVX1uqnYfTY0rjps5tm0eLkzhZmJqRnqG4toYcMeta || {},
    component: () => import("/app/apps/website/app/pages/nft-tickets.vue")
  },
  {
    name: "nft-tickets___pt",
    path: "/pt/nft-tickets",
    meta: nft_45ticketszcQw_45PVX1uqnYfTY0rjps5tm0eLkzhZmJqRnqG4toYcMeta || {},
    component: () => import("/app/apps/website/app/pages/nft-tickets.vue")
  },
  {
    name: "nft-tickets___ru",
    path: "/ru/nft-tickets",
    meta: nft_45ticketszcQw_45PVX1uqnYfTY0rjps5tm0eLkzhZmJqRnqG4toYcMeta || {},
    component: () => import("/app/apps/website/app/pages/nft-tickets.vue")
  },
  {
    name: "nft-tickets___tr",
    path: "/tr/nft-tickets",
    meta: nft_45ticketszcQw_45PVX1uqnYfTY0rjps5tm0eLkzhZmJqRnqG4toYcMeta || {},
    component: () => import("/app/apps/website/app/pages/nft-tickets.vue")
  },
  {
    name: "nft-tickets___uk",
    path: "/uk/nft-tickets",
    meta: nft_45ticketszcQw_45PVX1uqnYfTY0rjps5tm0eLkzhZmJqRnqG4toYcMeta || {},
    component: () => import("/app/apps/website/app/pages/nft-tickets.vue")
  },
  {
    name: "nft-tickets___zh",
    path: "/zh/nft-tickets",
    meta: nft_45ticketszcQw_45PVX1uqnYfTY0rjps5tm0eLkzhZmJqRnqG4toYcMeta || {},
    component: () => import("/app/apps/website/app/pages/nft-tickets.vue")
  },
  {
    name: "creator-pass___de",
    path: "/de/creator-pass",
    meta: creator_45passyRFT2geJUTpTFE3P0Oi_cTk_45RzDVXy1315jYKY0pWdsMeta || {},
    component: () => import("/app/apps/website/app/pages/creator-pass.vue")
  },
  {
    name: "creator-pass___en",
    path: "/creator-pass",
    meta: creator_45passyRFT2geJUTpTFE3P0Oi_cTk_45RzDVXy1315jYKY0pWdsMeta || {},
    component: () => import("/app/apps/website/app/pages/creator-pass.vue")
  },
  {
    name: "creator-pass___es",
    path: "/es/creator-pass",
    meta: creator_45passyRFT2geJUTpTFE3P0Oi_cTk_45RzDVXy1315jYKY0pWdsMeta || {},
    component: () => import("/app/apps/website/app/pages/creator-pass.vue")
  },
  {
    name: "creator-pass___fr",
    path: "/fr/creator-pass",
    meta: creator_45passyRFT2geJUTpTFE3P0Oi_cTk_45RzDVXy1315jYKY0pWdsMeta || {},
    component: () => import("/app/apps/website/app/pages/creator-pass.vue")
  },
  {
    name: "creator-pass___id",
    path: "/id/creator-pass",
    meta: creator_45passyRFT2geJUTpTFE3P0Oi_cTk_45RzDVXy1315jYKY0pWdsMeta || {},
    component: () => import("/app/apps/website/app/pages/creator-pass.vue")
  },
  {
    name: "creator-pass___it",
    path: "/it/creator-pass",
    meta: creator_45passyRFT2geJUTpTFE3P0Oi_cTk_45RzDVXy1315jYKY0pWdsMeta || {},
    component: () => import("/app/apps/website/app/pages/creator-pass.vue")
  },
  {
    name: "creator-pass___ja",
    path: "/ja/creator-pass",
    meta: creator_45passyRFT2geJUTpTFE3P0Oi_cTk_45RzDVXy1315jYKY0pWdsMeta || {},
    component: () => import("/app/apps/website/app/pages/creator-pass.vue")
  },
  {
    name: "creator-pass___ko",
    path: "/ko/creator-pass",
    meta: creator_45passyRFT2geJUTpTFE3P0Oi_cTk_45RzDVXy1315jYKY0pWdsMeta || {},
    component: () => import("/app/apps/website/app/pages/creator-pass.vue")
  },
  {
    name: "creator-pass___pl",
    path: "/pl/creator-pass",
    meta: creator_45passyRFT2geJUTpTFE3P0Oi_cTk_45RzDVXy1315jYKY0pWdsMeta || {},
    component: () => import("/app/apps/website/app/pages/creator-pass.vue")
  },
  {
    name: "creator-pass___pt",
    path: "/pt/creator-pass",
    meta: creator_45passyRFT2geJUTpTFE3P0Oi_cTk_45RzDVXy1315jYKY0pWdsMeta || {},
    component: () => import("/app/apps/website/app/pages/creator-pass.vue")
  },
  {
    name: "creator-pass___ru",
    path: "/ru/creator-pass",
    meta: creator_45passyRFT2geJUTpTFE3P0Oi_cTk_45RzDVXy1315jYKY0pWdsMeta || {},
    component: () => import("/app/apps/website/app/pages/creator-pass.vue")
  },
  {
    name: "creator-pass___tr",
    path: "/tr/creator-pass",
    meta: creator_45passyRFT2geJUTpTFE3P0Oi_cTk_45RzDVXy1315jYKY0pWdsMeta || {},
    component: () => import("/app/apps/website/app/pages/creator-pass.vue")
  },
  {
    name: "creator-pass___uk",
    path: "/uk/creator-pass",
    meta: creator_45passyRFT2geJUTpTFE3P0Oi_cTk_45RzDVXy1315jYKY0pWdsMeta || {},
    component: () => import("/app/apps/website/app/pages/creator-pass.vue")
  },
  {
    name: "creator-pass___zh",
    path: "/zh/creator-pass",
    meta: creator_45passyRFT2geJUTpTFE3P0Oi_cTk_45RzDVXy1315jYKY0pWdsMeta || {},
    component: () => import("/app/apps/website/app/pages/creator-pass.vue")
  },
  {
    name: "debug-lottie___de",
    path: "/de/debug/lottie",
    component: () => import("/app/apps/website/app/pages/debug/lottie.vue")
  },
  {
    name: "debug-lottie___en",
    path: "/debug/lottie",
    component: () => import("/app/apps/website/app/pages/debug/lottie.vue")
  },
  {
    name: "debug-lottie___es",
    path: "/es/debug/lottie",
    component: () => import("/app/apps/website/app/pages/debug/lottie.vue")
  },
  {
    name: "debug-lottie___fr",
    path: "/fr/debug/lottie",
    component: () => import("/app/apps/website/app/pages/debug/lottie.vue")
  },
  {
    name: "debug-lottie___id",
    path: "/id/debug/lottie",
    component: () => import("/app/apps/website/app/pages/debug/lottie.vue")
  },
  {
    name: "debug-lottie___it",
    path: "/it/debug/lottie",
    component: () => import("/app/apps/website/app/pages/debug/lottie.vue")
  },
  {
    name: "debug-lottie___ja",
    path: "/ja/debug/lottie",
    component: () => import("/app/apps/website/app/pages/debug/lottie.vue")
  },
  {
    name: "debug-lottie___ko",
    path: "/ko/debug/lottie",
    component: () => import("/app/apps/website/app/pages/debug/lottie.vue")
  },
  {
    name: "debug-lottie___pl",
    path: "/pl/debug/lottie",
    component: () => import("/app/apps/website/app/pages/debug/lottie.vue")
  },
  {
    name: "debug-lottie___pt",
    path: "/pt/debug/lottie",
    component: () => import("/app/apps/website/app/pages/debug/lottie.vue")
  },
  {
    name: "debug-lottie___ru",
    path: "/ru/debug/lottie",
    component: () => import("/app/apps/website/app/pages/debug/lottie.vue")
  },
  {
    name: "debug-lottie___tr",
    path: "/tr/debug/lottie",
    component: () => import("/app/apps/website/app/pages/debug/lottie.vue")
  },
  {
    name: "debug-lottie___uk",
    path: "/uk/debug/lottie",
    component: () => import("/app/apps/website/app/pages/debug/lottie.vue")
  },
  {
    name: "debug-lottie___zh",
    path: "/zh/debug/lottie",
    component: () => import("/app/apps/website/app/pages/debug/lottie.vue")
  },
  {
    name: "demos-slug___de",
    path: "/de/demos/:slug()",
    meta: _91slug_93uH10aIj0yuH0yUUq1NvLhLNqDLBq3BeV44TmBR1Ni_45QMeta || {},
    alias: ["/de/:slug"],
    component: () => import("/app/apps/website/app/pages/demos/[slug].vue")
  },
  {
    name: "demos-slug___en",
    path: "/demos/:slug()",
    meta: _91slug_93uH10aIj0yuH0yUUq1NvLhLNqDLBq3BeV44TmBR1Ni_45QMeta || {},
    alias: ["/:slug"],
    component: () => import("/app/apps/website/app/pages/demos/[slug].vue")
  },
  {
    name: "demos-slug___es",
    path: "/es/demos/:slug()",
    meta: _91slug_93uH10aIj0yuH0yUUq1NvLhLNqDLBq3BeV44TmBR1Ni_45QMeta || {},
    alias: ["/es/:slug"],
    component: () => import("/app/apps/website/app/pages/demos/[slug].vue")
  },
  {
    name: "demos-slug___fr",
    path: "/fr/demos/:slug()",
    meta: _91slug_93uH10aIj0yuH0yUUq1NvLhLNqDLBq3BeV44TmBR1Ni_45QMeta || {},
    alias: ["/fr/:slug"],
    component: () => import("/app/apps/website/app/pages/demos/[slug].vue")
  },
  {
    name: "demos-slug___id",
    path: "/id/demos/:slug()",
    meta: _91slug_93uH10aIj0yuH0yUUq1NvLhLNqDLBq3BeV44TmBR1Ni_45QMeta || {},
    alias: ["/id/:slug"],
    component: () => import("/app/apps/website/app/pages/demos/[slug].vue")
  },
  {
    name: "demos-slug___it",
    path: "/it/demos/:slug()",
    meta: _91slug_93uH10aIj0yuH0yUUq1NvLhLNqDLBq3BeV44TmBR1Ni_45QMeta || {},
    alias: ["/it/:slug"],
    component: () => import("/app/apps/website/app/pages/demos/[slug].vue")
  },
  {
    name: "demos-slug___ja",
    path: "/ja/demos/:slug()",
    meta: _91slug_93uH10aIj0yuH0yUUq1NvLhLNqDLBq3BeV44TmBR1Ni_45QMeta || {},
    alias: ["/ja/:slug"],
    component: () => import("/app/apps/website/app/pages/demos/[slug].vue")
  },
  {
    name: "demos-slug___ko",
    path: "/ko/demos/:slug()",
    meta: _91slug_93uH10aIj0yuH0yUUq1NvLhLNqDLBq3BeV44TmBR1Ni_45QMeta || {},
    alias: ["/ko/:slug"],
    component: () => import("/app/apps/website/app/pages/demos/[slug].vue")
  },
  {
    name: "demos-slug___pl",
    path: "/pl/demos/:slug()",
    meta: _91slug_93uH10aIj0yuH0yUUq1NvLhLNqDLBq3BeV44TmBR1Ni_45QMeta || {},
    alias: ["/pl/:slug"],
    component: () => import("/app/apps/website/app/pages/demos/[slug].vue")
  },
  {
    name: "demos-slug___pt",
    path: "/pt/demos/:slug()",
    meta: _91slug_93uH10aIj0yuH0yUUq1NvLhLNqDLBq3BeV44TmBR1Ni_45QMeta || {},
    alias: ["/pt/:slug"],
    component: () => import("/app/apps/website/app/pages/demos/[slug].vue")
  },
  {
    name: "demos-slug___ru",
    path: "/ru/demos/:slug()",
    meta: _91slug_93uH10aIj0yuH0yUUq1NvLhLNqDLBq3BeV44TmBR1Ni_45QMeta || {},
    alias: ["/ru/:slug"],
    component: () => import("/app/apps/website/app/pages/demos/[slug].vue")
  },
  {
    name: "demos-slug___tr",
    path: "/tr/demos/:slug()",
    meta: _91slug_93uH10aIj0yuH0yUUq1NvLhLNqDLBq3BeV44TmBR1Ni_45QMeta || {},
    alias: ["/tr/:slug"],
    component: () => import("/app/apps/website/app/pages/demos/[slug].vue")
  },
  {
    name: "demos-slug___uk",
    path: "/uk/demos/:slug()",
    meta: _91slug_93uH10aIj0yuH0yUUq1NvLhLNqDLBq3BeV44TmBR1Ni_45QMeta || {},
    alias: ["/uk/:slug"],
    component: () => import("/app/apps/website/app/pages/demos/[slug].vue")
  },
  {
    name: "demos-slug___zh",
    path: "/zh/demos/:slug()",
    meta: _91slug_93uH10aIj0yuH0yUUq1NvLhLNqDLBq3BeV44TmBR1Ni_45QMeta || {},
    alias: ["/zh/:slug"],
    component: () => import("/app/apps/website/app/pages/demos/[slug].vue")
  },
  {
    name: "events___de",
    path: "/de/events",
    meta: indexUXpeS9Sk3eKBe3zIaaWzScXDjrPg7LY6vnd2_9Jk_45ScMeta || {},
    component: () => import("/app/apps/website/app/pages/events/index.vue")
  },
  {
    name: "events___en",
    path: "/events",
    meta: indexUXpeS9Sk3eKBe3zIaaWzScXDjrPg7LY6vnd2_9Jk_45ScMeta || {},
    component: () => import("/app/apps/website/app/pages/events/index.vue")
  },
  {
    name: "events___es",
    path: "/es/events",
    meta: indexUXpeS9Sk3eKBe3zIaaWzScXDjrPg7LY6vnd2_9Jk_45ScMeta || {},
    component: () => import("/app/apps/website/app/pages/events/index.vue")
  },
  {
    name: "events___fr",
    path: "/fr/events",
    meta: indexUXpeS9Sk3eKBe3zIaaWzScXDjrPg7LY6vnd2_9Jk_45ScMeta || {},
    component: () => import("/app/apps/website/app/pages/events/index.vue")
  },
  {
    name: "events___id",
    path: "/id/events",
    meta: indexUXpeS9Sk3eKBe3zIaaWzScXDjrPg7LY6vnd2_9Jk_45ScMeta || {},
    component: () => import("/app/apps/website/app/pages/events/index.vue")
  },
  {
    name: "events___it",
    path: "/it/events",
    meta: indexUXpeS9Sk3eKBe3zIaaWzScXDjrPg7LY6vnd2_9Jk_45ScMeta || {},
    component: () => import("/app/apps/website/app/pages/events/index.vue")
  },
  {
    name: "events___ja",
    path: "/ja/events",
    meta: indexUXpeS9Sk3eKBe3zIaaWzScXDjrPg7LY6vnd2_9Jk_45ScMeta || {},
    component: () => import("/app/apps/website/app/pages/events/index.vue")
  },
  {
    name: "events___ko",
    path: "/ko/events",
    meta: indexUXpeS9Sk3eKBe3zIaaWzScXDjrPg7LY6vnd2_9Jk_45ScMeta || {},
    component: () => import("/app/apps/website/app/pages/events/index.vue")
  },
  {
    name: "events___pl",
    path: "/pl/events",
    meta: indexUXpeS9Sk3eKBe3zIaaWzScXDjrPg7LY6vnd2_9Jk_45ScMeta || {},
    component: () => import("/app/apps/website/app/pages/events/index.vue")
  },
  {
    name: "events___pt",
    path: "/pt/events",
    meta: indexUXpeS9Sk3eKBe3zIaaWzScXDjrPg7LY6vnd2_9Jk_45ScMeta || {},
    component: () => import("/app/apps/website/app/pages/events/index.vue")
  },
  {
    name: "events___ru",
    path: "/ru/events",
    meta: indexUXpeS9Sk3eKBe3zIaaWzScXDjrPg7LY6vnd2_9Jk_45ScMeta || {},
    component: () => import("/app/apps/website/app/pages/events/index.vue")
  },
  {
    name: "events___tr",
    path: "/tr/events",
    meta: indexUXpeS9Sk3eKBe3zIaaWzScXDjrPg7LY6vnd2_9Jk_45ScMeta || {},
    component: () => import("/app/apps/website/app/pages/events/index.vue")
  },
  {
    name: "events___uk",
    path: "/uk/events",
    meta: indexUXpeS9Sk3eKBe3zIaaWzScXDjrPg7LY6vnd2_9Jk_45ScMeta || {},
    component: () => import("/app/apps/website/app/pages/events/index.vue")
  },
  {
    name: "events___zh",
    path: "/zh/events",
    meta: indexUXpeS9Sk3eKBe3zIaaWzScXDjrPg7LY6vnd2_9Jk_45ScMeta || {},
    component: () => import("/app/apps/website/app/pages/events/index.vue")
  },
  {
    name: "founder-pass___de",
    path: "/de/founder-pass",
    meta: founder_45passMcrn3t97twvrT8N2dhvNEsx4_k85Y0Hlo_45kDDhHzjsQMeta || {},
    component: () => import("/app/apps/website/app/pages/founder-pass.vue")
  },
  {
    name: "founder-pass___en",
    path: "/founder-pass",
    meta: founder_45passMcrn3t97twvrT8N2dhvNEsx4_k85Y0Hlo_45kDDhHzjsQMeta || {},
    component: () => import("/app/apps/website/app/pages/founder-pass.vue")
  },
  {
    name: "founder-pass___es",
    path: "/es/founder-pass",
    meta: founder_45passMcrn3t97twvrT8N2dhvNEsx4_k85Y0Hlo_45kDDhHzjsQMeta || {},
    component: () => import("/app/apps/website/app/pages/founder-pass.vue")
  },
  {
    name: "founder-pass___fr",
    path: "/fr/founder-pass",
    meta: founder_45passMcrn3t97twvrT8N2dhvNEsx4_k85Y0Hlo_45kDDhHzjsQMeta || {},
    component: () => import("/app/apps/website/app/pages/founder-pass.vue")
  },
  {
    name: "founder-pass___id",
    path: "/id/founder-pass",
    meta: founder_45passMcrn3t97twvrT8N2dhvNEsx4_k85Y0Hlo_45kDDhHzjsQMeta || {},
    component: () => import("/app/apps/website/app/pages/founder-pass.vue")
  },
  {
    name: "founder-pass___it",
    path: "/it/founder-pass",
    meta: founder_45passMcrn3t97twvrT8N2dhvNEsx4_k85Y0Hlo_45kDDhHzjsQMeta || {},
    component: () => import("/app/apps/website/app/pages/founder-pass.vue")
  },
  {
    name: "founder-pass___ja",
    path: "/ja/founder-pass",
    meta: founder_45passMcrn3t97twvrT8N2dhvNEsx4_k85Y0Hlo_45kDDhHzjsQMeta || {},
    component: () => import("/app/apps/website/app/pages/founder-pass.vue")
  },
  {
    name: "founder-pass___ko",
    path: "/ko/founder-pass",
    meta: founder_45passMcrn3t97twvrT8N2dhvNEsx4_k85Y0Hlo_45kDDhHzjsQMeta || {},
    component: () => import("/app/apps/website/app/pages/founder-pass.vue")
  },
  {
    name: "founder-pass___pl",
    path: "/pl/founder-pass",
    meta: founder_45passMcrn3t97twvrT8N2dhvNEsx4_k85Y0Hlo_45kDDhHzjsQMeta || {},
    component: () => import("/app/apps/website/app/pages/founder-pass.vue")
  },
  {
    name: "founder-pass___pt",
    path: "/pt/founder-pass",
    meta: founder_45passMcrn3t97twvrT8N2dhvNEsx4_k85Y0Hlo_45kDDhHzjsQMeta || {},
    component: () => import("/app/apps/website/app/pages/founder-pass.vue")
  },
  {
    name: "founder-pass___ru",
    path: "/ru/founder-pass",
    meta: founder_45passMcrn3t97twvrT8N2dhvNEsx4_k85Y0Hlo_45kDDhHzjsQMeta || {},
    component: () => import("/app/apps/website/app/pages/founder-pass.vue")
  },
  {
    name: "founder-pass___tr",
    path: "/tr/founder-pass",
    meta: founder_45passMcrn3t97twvrT8N2dhvNEsx4_k85Y0Hlo_45kDDhHzjsQMeta || {},
    component: () => import("/app/apps/website/app/pages/founder-pass.vue")
  },
  {
    name: "founder-pass___uk",
    path: "/uk/founder-pass",
    meta: founder_45passMcrn3t97twvrT8N2dhvNEsx4_k85Y0Hlo_45kDDhHzjsQMeta || {},
    component: () => import("/app/apps/website/app/pages/founder-pass.vue")
  },
  {
    name: "founder-pass___zh",
    path: "/zh/founder-pass",
    meta: founder_45passMcrn3t97twvrT8N2dhvNEsx4_k85Y0Hlo_45kDDhHzjsQMeta || {},
    component: () => import("/app/apps/website/app/pages/founder-pass.vue")
  },
  {
    name: "how-to___de",
    path: "/de/how-to",
    meta: indexMPNbdBu3fRhQmZB53o1PVtJg_45NbQwX_45Sweo15saQkbYMeta || {},
    component: () => import("/app/apps/website/app/pages/how-to/index.vue")
  },
  {
    name: "how-to___en",
    path: "/how-to",
    meta: indexMPNbdBu3fRhQmZB53o1PVtJg_45NbQwX_45Sweo15saQkbYMeta || {},
    component: () => import("/app/apps/website/app/pages/how-to/index.vue")
  },
  {
    name: "how-to___es",
    path: "/es/how-to",
    meta: indexMPNbdBu3fRhQmZB53o1PVtJg_45NbQwX_45Sweo15saQkbYMeta || {},
    component: () => import("/app/apps/website/app/pages/how-to/index.vue")
  },
  {
    name: "how-to___fr",
    path: "/fr/how-to",
    meta: indexMPNbdBu3fRhQmZB53o1PVtJg_45NbQwX_45Sweo15saQkbYMeta || {},
    component: () => import("/app/apps/website/app/pages/how-to/index.vue")
  },
  {
    name: "how-to___id",
    path: "/id/how-to",
    meta: indexMPNbdBu3fRhQmZB53o1PVtJg_45NbQwX_45Sweo15saQkbYMeta || {},
    component: () => import("/app/apps/website/app/pages/how-to/index.vue")
  },
  {
    name: "how-to___it",
    path: "/it/how-to",
    meta: indexMPNbdBu3fRhQmZB53o1PVtJg_45NbQwX_45Sweo15saQkbYMeta || {},
    component: () => import("/app/apps/website/app/pages/how-to/index.vue")
  },
  {
    name: "how-to___ja",
    path: "/ja/how-to",
    meta: indexMPNbdBu3fRhQmZB53o1PVtJg_45NbQwX_45Sweo15saQkbYMeta || {},
    component: () => import("/app/apps/website/app/pages/how-to/index.vue")
  },
  {
    name: "how-to___ko",
    path: "/ko/how-to",
    meta: indexMPNbdBu3fRhQmZB53o1PVtJg_45NbQwX_45Sweo15saQkbYMeta || {},
    component: () => import("/app/apps/website/app/pages/how-to/index.vue")
  },
  {
    name: "how-to___pl",
    path: "/pl/how-to",
    meta: indexMPNbdBu3fRhQmZB53o1PVtJg_45NbQwX_45Sweo15saQkbYMeta || {},
    component: () => import("/app/apps/website/app/pages/how-to/index.vue")
  },
  {
    name: "how-to___pt",
    path: "/pt/how-to",
    meta: indexMPNbdBu3fRhQmZB53o1PVtJg_45NbQwX_45Sweo15saQkbYMeta || {},
    component: () => import("/app/apps/website/app/pages/how-to/index.vue")
  },
  {
    name: "how-to___ru",
    path: "/ru/how-to",
    meta: indexMPNbdBu3fRhQmZB53o1PVtJg_45NbQwX_45Sweo15saQkbYMeta || {},
    component: () => import("/app/apps/website/app/pages/how-to/index.vue")
  },
  {
    name: "how-to___tr",
    path: "/tr/how-to",
    meta: indexMPNbdBu3fRhQmZB53o1PVtJg_45NbQwX_45Sweo15saQkbYMeta || {},
    component: () => import("/app/apps/website/app/pages/how-to/index.vue")
  },
  {
    name: "how-to___uk",
    path: "/uk/how-to",
    meta: indexMPNbdBu3fRhQmZB53o1PVtJg_45NbQwX_45Sweo15saQkbYMeta || {},
    component: () => import("/app/apps/website/app/pages/how-to/index.vue")
  },
  {
    name: "how-to___zh",
    path: "/zh/how-to",
    meta: indexMPNbdBu3fRhQmZB53o1PVtJg_45NbQwX_45Sweo15saQkbYMeta || {},
    component: () => import("/app/apps/website/app/pages/how-to/index.vue")
  },
  {
    name: "token-gating___de",
    path: "/de/token-gating",
    meta: token_45gatingCon7oYQ6R5AnW5O1yUIfzucnd1qKyJOTWqsPbRoG8WAMeta || {},
    component: () => import("/app/apps/website/app/pages/token-gating.vue")
  },
  {
    name: "token-gating___en",
    path: "/token-gating",
    meta: token_45gatingCon7oYQ6R5AnW5O1yUIfzucnd1qKyJOTWqsPbRoG8WAMeta || {},
    component: () => import("/app/apps/website/app/pages/token-gating.vue")
  },
  {
    name: "token-gating___es",
    path: "/es/token-gating",
    meta: token_45gatingCon7oYQ6R5AnW5O1yUIfzucnd1qKyJOTWqsPbRoG8WAMeta || {},
    component: () => import("/app/apps/website/app/pages/token-gating.vue")
  },
  {
    name: "token-gating___fr",
    path: "/fr/token-gating",
    meta: token_45gatingCon7oYQ6R5AnW5O1yUIfzucnd1qKyJOTWqsPbRoG8WAMeta || {},
    component: () => import("/app/apps/website/app/pages/token-gating.vue")
  },
  {
    name: "token-gating___id",
    path: "/id/token-gating",
    meta: token_45gatingCon7oYQ6R5AnW5O1yUIfzucnd1qKyJOTWqsPbRoG8WAMeta || {},
    component: () => import("/app/apps/website/app/pages/token-gating.vue")
  },
  {
    name: "token-gating___it",
    path: "/it/token-gating",
    meta: token_45gatingCon7oYQ6R5AnW5O1yUIfzucnd1qKyJOTWqsPbRoG8WAMeta || {},
    component: () => import("/app/apps/website/app/pages/token-gating.vue")
  },
  {
    name: "token-gating___ja",
    path: "/ja/token-gating",
    meta: token_45gatingCon7oYQ6R5AnW5O1yUIfzucnd1qKyJOTWqsPbRoG8WAMeta || {},
    component: () => import("/app/apps/website/app/pages/token-gating.vue")
  },
  {
    name: "token-gating___ko",
    path: "/ko/token-gating",
    meta: token_45gatingCon7oYQ6R5AnW5O1yUIfzucnd1qKyJOTWqsPbRoG8WAMeta || {},
    component: () => import("/app/apps/website/app/pages/token-gating.vue")
  },
  {
    name: "token-gating___pl",
    path: "/pl/token-gating",
    meta: token_45gatingCon7oYQ6R5AnW5O1yUIfzucnd1qKyJOTWqsPbRoG8WAMeta || {},
    component: () => import("/app/apps/website/app/pages/token-gating.vue")
  },
  {
    name: "token-gating___pt",
    path: "/pt/token-gating",
    meta: token_45gatingCon7oYQ6R5AnW5O1yUIfzucnd1qKyJOTWqsPbRoG8WAMeta || {},
    component: () => import("/app/apps/website/app/pages/token-gating.vue")
  },
  {
    name: "token-gating___ru",
    path: "/ru/token-gating",
    meta: token_45gatingCon7oYQ6R5AnW5O1yUIfzucnd1qKyJOTWqsPbRoG8WAMeta || {},
    component: () => import("/app/apps/website/app/pages/token-gating.vue")
  },
  {
    name: "token-gating___tr",
    path: "/tr/token-gating",
    meta: token_45gatingCon7oYQ6R5AnW5O1yUIfzucnd1qKyJOTWqsPbRoG8WAMeta || {},
    component: () => import("/app/apps/website/app/pages/token-gating.vue")
  },
  {
    name: "token-gating___uk",
    path: "/uk/token-gating",
    meta: token_45gatingCon7oYQ6R5AnW5O1yUIfzucnd1qKyJOTWqsPbRoG8WAMeta || {},
    component: () => import("/app/apps/website/app/pages/token-gating.vue")
  },
  {
    name: "token-gating___zh",
    path: "/zh/token-gating",
    meta: token_45gatingCon7oYQ6R5AnW5O1yUIfzucnd1qKyJOTWqsPbRoG8WAMeta || {},
    component: () => import("/app/apps/website/app/pages/token-gating.vue")
  },
  {
    name: "how-to-slug___de",
    path: "/de/how-to/:slug()",
    meta: _91slug_935JLsj_45sG1MFqPBnDVNxEed3fWfACfk9zKxS8Rj_45VwagMeta || {},
    component: () => import("/app/apps/website/app/pages/how-to/[slug].vue")
  },
  {
    name: "how-to-slug___en",
    path: "/how-to/:slug()",
    meta: _91slug_935JLsj_45sG1MFqPBnDVNxEed3fWfACfk9zKxS8Rj_45VwagMeta || {},
    component: () => import("/app/apps/website/app/pages/how-to/[slug].vue")
  },
  {
    name: "how-to-slug___es",
    path: "/es/how-to/:slug()",
    meta: _91slug_935JLsj_45sG1MFqPBnDVNxEed3fWfACfk9zKxS8Rj_45VwagMeta || {},
    component: () => import("/app/apps/website/app/pages/how-to/[slug].vue")
  },
  {
    name: "how-to-slug___fr",
    path: "/fr/how-to/:slug()",
    meta: _91slug_935JLsj_45sG1MFqPBnDVNxEed3fWfACfk9zKxS8Rj_45VwagMeta || {},
    component: () => import("/app/apps/website/app/pages/how-to/[slug].vue")
  },
  {
    name: "how-to-slug___id",
    path: "/id/how-to/:slug()",
    meta: _91slug_935JLsj_45sG1MFqPBnDVNxEed3fWfACfk9zKxS8Rj_45VwagMeta || {},
    component: () => import("/app/apps/website/app/pages/how-to/[slug].vue")
  },
  {
    name: "how-to-slug___it",
    path: "/it/how-to/:slug()",
    meta: _91slug_935JLsj_45sG1MFqPBnDVNxEed3fWfACfk9zKxS8Rj_45VwagMeta || {},
    component: () => import("/app/apps/website/app/pages/how-to/[slug].vue")
  },
  {
    name: "how-to-slug___ja",
    path: "/ja/how-to/:slug()",
    meta: _91slug_935JLsj_45sG1MFqPBnDVNxEed3fWfACfk9zKxS8Rj_45VwagMeta || {},
    component: () => import("/app/apps/website/app/pages/how-to/[slug].vue")
  },
  {
    name: "how-to-slug___ko",
    path: "/ko/how-to/:slug()",
    meta: _91slug_935JLsj_45sG1MFqPBnDVNxEed3fWfACfk9zKxS8Rj_45VwagMeta || {},
    component: () => import("/app/apps/website/app/pages/how-to/[slug].vue")
  },
  {
    name: "how-to-slug___pl",
    path: "/pl/how-to/:slug()",
    meta: _91slug_935JLsj_45sG1MFqPBnDVNxEed3fWfACfk9zKxS8Rj_45VwagMeta || {},
    component: () => import("/app/apps/website/app/pages/how-to/[slug].vue")
  },
  {
    name: "how-to-slug___pt",
    path: "/pt/how-to/:slug()",
    meta: _91slug_935JLsj_45sG1MFqPBnDVNxEed3fWfACfk9zKxS8Rj_45VwagMeta || {},
    component: () => import("/app/apps/website/app/pages/how-to/[slug].vue")
  },
  {
    name: "how-to-slug___ru",
    path: "/ru/how-to/:slug()",
    meta: _91slug_935JLsj_45sG1MFqPBnDVNxEed3fWfACfk9zKxS8Rj_45VwagMeta || {},
    component: () => import("/app/apps/website/app/pages/how-to/[slug].vue")
  },
  {
    name: "how-to-slug___tr",
    path: "/tr/how-to/:slug()",
    meta: _91slug_935JLsj_45sG1MFqPBnDVNxEed3fWfACfk9zKxS8Rj_45VwagMeta || {},
    component: () => import("/app/apps/website/app/pages/how-to/[slug].vue")
  },
  {
    name: "how-to-slug___uk",
    path: "/uk/how-to/:slug()",
    meta: _91slug_935JLsj_45sG1MFqPBnDVNxEed3fWfACfk9zKxS8Rj_45VwagMeta || {},
    component: () => import("/app/apps/website/app/pages/how-to/[slug].vue")
  },
  {
    name: "how-to-slug___zh",
    path: "/zh/how-to/:slug()",
    meta: _91slug_935JLsj_45sG1MFqPBnDVNxEed3fWfACfk9zKxS8Rj_45VwagMeta || {},
    component: () => import("/app/apps/website/app/pages/how-to/[slug].vue")
  },
  {
    name: "hubs-hub_slug___de",
    path: "/de/hubs/:hub_slug()",
    meta: _91hub_slug_93pbzg9lhD3MgL7lKfkITC8NP5ZGPPwnHS_453nWTp3NdQAMeta || {},
    component: () => import("/app/apps/website/app/pages/hubs/[hub_slug].vue")
  },
  {
    name: "hubs-hub_slug___en",
    path: "/hubs/:hub_slug()",
    meta: _91hub_slug_93pbzg9lhD3MgL7lKfkITC8NP5ZGPPwnHS_453nWTp3NdQAMeta || {},
    component: () => import("/app/apps/website/app/pages/hubs/[hub_slug].vue")
  },
  {
    name: "hubs-hub_slug___es",
    path: "/es/hubs/:hub_slug()",
    meta: _91hub_slug_93pbzg9lhD3MgL7lKfkITC8NP5ZGPPwnHS_453nWTp3NdQAMeta || {},
    component: () => import("/app/apps/website/app/pages/hubs/[hub_slug].vue")
  },
  {
    name: "hubs-hub_slug___fr",
    path: "/fr/hubs/:hub_slug()",
    meta: _91hub_slug_93pbzg9lhD3MgL7lKfkITC8NP5ZGPPwnHS_453nWTp3NdQAMeta || {},
    component: () => import("/app/apps/website/app/pages/hubs/[hub_slug].vue")
  },
  {
    name: "hubs-hub_slug___id",
    path: "/id/hubs/:hub_slug()",
    meta: _91hub_slug_93pbzg9lhD3MgL7lKfkITC8NP5ZGPPwnHS_453nWTp3NdQAMeta || {},
    component: () => import("/app/apps/website/app/pages/hubs/[hub_slug].vue")
  },
  {
    name: "hubs-hub_slug___it",
    path: "/it/hubs/:hub_slug()",
    meta: _91hub_slug_93pbzg9lhD3MgL7lKfkITC8NP5ZGPPwnHS_453nWTp3NdQAMeta || {},
    component: () => import("/app/apps/website/app/pages/hubs/[hub_slug].vue")
  },
  {
    name: "hubs-hub_slug___ja",
    path: "/ja/hubs/:hub_slug()",
    meta: _91hub_slug_93pbzg9lhD3MgL7lKfkITC8NP5ZGPPwnHS_453nWTp3NdQAMeta || {},
    component: () => import("/app/apps/website/app/pages/hubs/[hub_slug].vue")
  },
  {
    name: "hubs-hub_slug___ko",
    path: "/ko/hubs/:hub_slug()",
    meta: _91hub_slug_93pbzg9lhD3MgL7lKfkITC8NP5ZGPPwnHS_453nWTp3NdQAMeta || {},
    component: () => import("/app/apps/website/app/pages/hubs/[hub_slug].vue")
  },
  {
    name: "hubs-hub_slug___pl",
    path: "/pl/hubs/:hub_slug()",
    meta: _91hub_slug_93pbzg9lhD3MgL7lKfkITC8NP5ZGPPwnHS_453nWTp3NdQAMeta || {},
    component: () => import("/app/apps/website/app/pages/hubs/[hub_slug].vue")
  },
  {
    name: "hubs-hub_slug___pt",
    path: "/pt/hubs/:hub_slug()",
    meta: _91hub_slug_93pbzg9lhD3MgL7lKfkITC8NP5ZGPPwnHS_453nWTp3NdQAMeta || {},
    component: () => import("/app/apps/website/app/pages/hubs/[hub_slug].vue")
  },
  {
    name: "hubs-hub_slug___ru",
    path: "/ru/hubs/:hub_slug()",
    meta: _91hub_slug_93pbzg9lhD3MgL7lKfkITC8NP5ZGPPwnHS_453nWTp3NdQAMeta || {},
    component: () => import("/app/apps/website/app/pages/hubs/[hub_slug].vue")
  },
  {
    name: "hubs-hub_slug___tr",
    path: "/tr/hubs/:hub_slug()",
    meta: _91hub_slug_93pbzg9lhD3MgL7lKfkITC8NP5ZGPPwnHS_453nWTp3NdQAMeta || {},
    component: () => import("/app/apps/website/app/pages/hubs/[hub_slug].vue")
  },
  {
    name: "hubs-hub_slug___uk",
    path: "/uk/hubs/:hub_slug()",
    meta: _91hub_slug_93pbzg9lhD3MgL7lKfkITC8NP5ZGPPwnHS_453nWTp3NdQAMeta || {},
    component: () => import("/app/apps/website/app/pages/hubs/[hub_slug].vue")
  },
  {
    name: "hubs-hub_slug___zh",
    path: "/zh/hubs/:hub_slug()",
    meta: _91hub_slug_93pbzg9lhD3MgL7lKfkITC8NP5ZGPPwnHS_453nWTp3NdQAMeta || {},
    component: () => import("/app/apps/website/app/pages/hubs/[hub_slug].vue")
  },
  {
    name: "blog-blog_slug___de",
    path: "/de/blog/:blog_slug()",
    meta: _91blog_slug_93pGdczDTP5NzL_Iehcfma5S9X41KW0stGD0JZBCYicy4Meta || {},
    component: () => import("/app/apps/website/app/pages/blog/[blog_slug].vue")
  },
  {
    name: "blog-blog_slug___en",
    path: "/blog/:blog_slug()",
    meta: _91blog_slug_93pGdczDTP5NzL_Iehcfma5S9X41KW0stGD0JZBCYicy4Meta || {},
    component: () => import("/app/apps/website/app/pages/blog/[blog_slug].vue")
  },
  {
    name: "blog-blog_slug___es",
    path: "/es/blog/:blog_slug()",
    meta: _91blog_slug_93pGdczDTP5NzL_Iehcfma5S9X41KW0stGD0JZBCYicy4Meta || {},
    component: () => import("/app/apps/website/app/pages/blog/[blog_slug].vue")
  },
  {
    name: "blog-blog_slug___fr",
    path: "/fr/blog/:blog_slug()",
    meta: _91blog_slug_93pGdczDTP5NzL_Iehcfma5S9X41KW0stGD0JZBCYicy4Meta || {},
    component: () => import("/app/apps/website/app/pages/blog/[blog_slug].vue")
  },
  {
    name: "blog-blog_slug___id",
    path: "/id/blog/:blog_slug()",
    meta: _91blog_slug_93pGdczDTP5NzL_Iehcfma5S9X41KW0stGD0JZBCYicy4Meta || {},
    component: () => import("/app/apps/website/app/pages/blog/[blog_slug].vue")
  },
  {
    name: "blog-blog_slug___it",
    path: "/it/blog/:blog_slug()",
    meta: _91blog_slug_93pGdczDTP5NzL_Iehcfma5S9X41KW0stGD0JZBCYicy4Meta || {},
    component: () => import("/app/apps/website/app/pages/blog/[blog_slug].vue")
  },
  {
    name: "blog-blog_slug___ja",
    path: "/ja/blog/:blog_slug()",
    meta: _91blog_slug_93pGdczDTP5NzL_Iehcfma5S9X41KW0stGD0JZBCYicy4Meta || {},
    component: () => import("/app/apps/website/app/pages/blog/[blog_slug].vue")
  },
  {
    name: "blog-blog_slug___ko",
    path: "/ko/blog/:blog_slug()",
    meta: _91blog_slug_93pGdczDTP5NzL_Iehcfma5S9X41KW0stGD0JZBCYicy4Meta || {},
    component: () => import("/app/apps/website/app/pages/blog/[blog_slug].vue")
  },
  {
    name: "blog-blog_slug___pl",
    path: "/pl/blog/:blog_slug()",
    meta: _91blog_slug_93pGdczDTP5NzL_Iehcfma5S9X41KW0stGD0JZBCYicy4Meta || {},
    component: () => import("/app/apps/website/app/pages/blog/[blog_slug].vue")
  },
  {
    name: "blog-blog_slug___pt",
    path: "/pt/blog/:blog_slug()",
    meta: _91blog_slug_93pGdczDTP5NzL_Iehcfma5S9X41KW0stGD0JZBCYicy4Meta || {},
    component: () => import("/app/apps/website/app/pages/blog/[blog_slug].vue")
  },
  {
    name: "blog-blog_slug___ru",
    path: "/ru/blog/:blog_slug()",
    meta: _91blog_slug_93pGdczDTP5NzL_Iehcfma5S9X41KW0stGD0JZBCYicy4Meta || {},
    component: () => import("/app/apps/website/app/pages/blog/[blog_slug].vue")
  },
  {
    name: "blog-blog_slug___tr",
    path: "/tr/blog/:blog_slug()",
    meta: _91blog_slug_93pGdczDTP5NzL_Iehcfma5S9X41KW0stGD0JZBCYicy4Meta || {},
    component: () => import("/app/apps/website/app/pages/blog/[blog_slug].vue")
  },
  {
    name: "blog-blog_slug___uk",
    path: "/uk/blog/:blog_slug()",
    meta: _91blog_slug_93pGdczDTP5NzL_Iehcfma5S9X41KW0stGD0JZBCYicy4Meta || {},
    component: () => import("/app/apps/website/app/pages/blog/[blog_slug].vue")
  },
  {
    name: "blog-blog_slug___zh",
    path: "/zh/blog/:blog_slug()",
    meta: _91blog_slug_93pGdczDTP5NzL_Iehcfma5S9X41KW0stGD0JZBCYicy4Meta || {},
    component: () => import("/app/apps/website/app/pages/blog/[blog_slug].vue")
  },
  {
    name: "paid-content-nft___de",
    path: "/de/paid-content-nft",
    meta: paid_45content_45nftS4SUctpC6b0DJHSn9jiYxLmSqyHvdPzREw1DmlHoYMsMeta || {},
    component: () => import("/app/apps/website/app/pages/paid-content-nft.vue")
  },
  {
    name: "paid-content-nft___en",
    path: "/paid-content-nft",
    meta: paid_45content_45nftS4SUctpC6b0DJHSn9jiYxLmSqyHvdPzREw1DmlHoYMsMeta || {},
    component: () => import("/app/apps/website/app/pages/paid-content-nft.vue")
  },
  {
    name: "paid-content-nft___es",
    path: "/es/paid-content-nft",
    meta: paid_45content_45nftS4SUctpC6b0DJHSn9jiYxLmSqyHvdPzREw1DmlHoYMsMeta || {},
    component: () => import("/app/apps/website/app/pages/paid-content-nft.vue")
  },
  {
    name: "paid-content-nft___fr",
    path: "/fr/paid-content-nft",
    meta: paid_45content_45nftS4SUctpC6b0DJHSn9jiYxLmSqyHvdPzREw1DmlHoYMsMeta || {},
    component: () => import("/app/apps/website/app/pages/paid-content-nft.vue")
  },
  {
    name: "paid-content-nft___id",
    path: "/id/paid-content-nft",
    meta: paid_45content_45nftS4SUctpC6b0DJHSn9jiYxLmSqyHvdPzREw1DmlHoYMsMeta || {},
    component: () => import("/app/apps/website/app/pages/paid-content-nft.vue")
  },
  {
    name: "paid-content-nft___it",
    path: "/it/paid-content-nft",
    meta: paid_45content_45nftS4SUctpC6b0DJHSn9jiYxLmSqyHvdPzREw1DmlHoYMsMeta || {},
    component: () => import("/app/apps/website/app/pages/paid-content-nft.vue")
  },
  {
    name: "paid-content-nft___ja",
    path: "/ja/paid-content-nft",
    meta: paid_45content_45nftS4SUctpC6b0DJHSn9jiYxLmSqyHvdPzREw1DmlHoYMsMeta || {},
    component: () => import("/app/apps/website/app/pages/paid-content-nft.vue")
  },
  {
    name: "paid-content-nft___ko",
    path: "/ko/paid-content-nft",
    meta: paid_45content_45nftS4SUctpC6b0DJHSn9jiYxLmSqyHvdPzREw1DmlHoYMsMeta || {},
    component: () => import("/app/apps/website/app/pages/paid-content-nft.vue")
  },
  {
    name: "paid-content-nft___pl",
    path: "/pl/paid-content-nft",
    meta: paid_45content_45nftS4SUctpC6b0DJHSn9jiYxLmSqyHvdPzREw1DmlHoYMsMeta || {},
    component: () => import("/app/apps/website/app/pages/paid-content-nft.vue")
  },
  {
    name: "paid-content-nft___pt",
    path: "/pt/paid-content-nft",
    meta: paid_45content_45nftS4SUctpC6b0DJHSn9jiYxLmSqyHvdPzREw1DmlHoYMsMeta || {},
    component: () => import("/app/apps/website/app/pages/paid-content-nft.vue")
  },
  {
    name: "paid-content-nft___ru",
    path: "/ru/paid-content-nft",
    meta: paid_45content_45nftS4SUctpC6b0DJHSn9jiYxLmSqyHvdPzREw1DmlHoYMsMeta || {},
    component: () => import("/app/apps/website/app/pages/paid-content-nft.vue")
  },
  {
    name: "paid-content-nft___tr",
    path: "/tr/paid-content-nft",
    meta: paid_45content_45nftS4SUctpC6b0DJHSn9jiYxLmSqyHvdPzREw1DmlHoYMsMeta || {},
    component: () => import("/app/apps/website/app/pages/paid-content-nft.vue")
  },
  {
    name: "paid-content-nft___uk",
    path: "/uk/paid-content-nft",
    meta: paid_45content_45nftS4SUctpC6b0DJHSn9jiYxLmSqyHvdPzREw1DmlHoYMsMeta || {},
    component: () => import("/app/apps/website/app/pages/paid-content-nft.vue")
  },
  {
    name: "paid-content-nft___zh",
    path: "/zh/paid-content-nft",
    meta: paid_45content_45nftS4SUctpC6b0DJHSn9jiYxLmSqyHvdPzREw1DmlHoYMsMeta || {},
    component: () => import("/app/apps/website/app/pages/paid-content-nft.vue")
  },
  {
    name: "debug-hub-hubId___de",
    path: "/de/debug/hub/:hubId()",
    component: () => import("/app/apps/website/app/pages/debug/hub/[hubId].vue")
  },
  {
    name: "debug-hub-hubId___en",
    path: "/debug/hub/:hubId()",
    component: () => import("/app/apps/website/app/pages/debug/hub/[hubId].vue")
  },
  {
    name: "debug-hub-hubId___es",
    path: "/es/debug/hub/:hubId()",
    component: () => import("/app/apps/website/app/pages/debug/hub/[hubId].vue")
  },
  {
    name: "debug-hub-hubId___fr",
    path: "/fr/debug/hub/:hubId()",
    component: () => import("/app/apps/website/app/pages/debug/hub/[hubId].vue")
  },
  {
    name: "debug-hub-hubId___id",
    path: "/id/debug/hub/:hubId()",
    component: () => import("/app/apps/website/app/pages/debug/hub/[hubId].vue")
  },
  {
    name: "debug-hub-hubId___it",
    path: "/it/debug/hub/:hubId()",
    component: () => import("/app/apps/website/app/pages/debug/hub/[hubId].vue")
  },
  {
    name: "debug-hub-hubId___ja",
    path: "/ja/debug/hub/:hubId()",
    component: () => import("/app/apps/website/app/pages/debug/hub/[hubId].vue")
  },
  {
    name: "debug-hub-hubId___ko",
    path: "/ko/debug/hub/:hubId()",
    component: () => import("/app/apps/website/app/pages/debug/hub/[hubId].vue")
  },
  {
    name: "debug-hub-hubId___pl",
    path: "/pl/debug/hub/:hubId()",
    component: () => import("/app/apps/website/app/pages/debug/hub/[hubId].vue")
  },
  {
    name: "debug-hub-hubId___pt",
    path: "/pt/debug/hub/:hubId()",
    component: () => import("/app/apps/website/app/pages/debug/hub/[hubId].vue")
  },
  {
    name: "debug-hub-hubId___ru",
    path: "/ru/debug/hub/:hubId()",
    component: () => import("/app/apps/website/app/pages/debug/hub/[hubId].vue")
  },
  {
    name: "debug-hub-hubId___tr",
    path: "/tr/debug/hub/:hubId()",
    component: () => import("/app/apps/website/app/pages/debug/hub/[hubId].vue")
  },
  {
    name: "debug-hub-hubId___uk",
    path: "/uk/debug/hub/:hubId()",
    component: () => import("/app/apps/website/app/pages/debug/hub/[hubId].vue")
  },
  {
    name: "debug-hub-hubId___zh",
    path: "/zh/debug/hub/:hubId()",
    component: () => import("/app/apps/website/app/pages/debug/hub/[hubId].vue")
  },
  {
    name: "events-event_slug___de",
    path: "/de/events/:event_slug()",
    meta: _91event_slug_936OcSwlcPsfyxVzUMON__ZGf_45EYaDUpH2xHgnkBUaa_45IMeta || {},
    alias: ["/de/hubs/:hub_slug?/events/:event_slug"],
    component: () => import("/app/apps/website/app/pages/events/[event_slug].vue")
  },
  {
    name: "events-event_slug___en",
    path: "/events/:event_slug()",
    meta: _91event_slug_936OcSwlcPsfyxVzUMON__ZGf_45EYaDUpH2xHgnkBUaa_45IMeta || {},
    alias: ["/hubs/:hub_slug?/events/:event_slug"],
    component: () => import("/app/apps/website/app/pages/events/[event_slug].vue")
  },
  {
    name: "events-event_slug___es",
    path: "/es/events/:event_slug()",
    meta: _91event_slug_936OcSwlcPsfyxVzUMON__ZGf_45EYaDUpH2xHgnkBUaa_45IMeta || {},
    alias: ["/es/hubs/:hub_slug?/events/:event_slug"],
    component: () => import("/app/apps/website/app/pages/events/[event_slug].vue")
  },
  {
    name: "events-event_slug___fr",
    path: "/fr/events/:event_slug()",
    meta: _91event_slug_936OcSwlcPsfyxVzUMON__ZGf_45EYaDUpH2xHgnkBUaa_45IMeta || {},
    alias: ["/fr/hubs/:hub_slug?/events/:event_slug"],
    component: () => import("/app/apps/website/app/pages/events/[event_slug].vue")
  },
  {
    name: "events-event_slug___id",
    path: "/id/events/:event_slug()",
    meta: _91event_slug_936OcSwlcPsfyxVzUMON__ZGf_45EYaDUpH2xHgnkBUaa_45IMeta || {},
    alias: ["/id/hubs/:hub_slug?/events/:event_slug"],
    component: () => import("/app/apps/website/app/pages/events/[event_slug].vue")
  },
  {
    name: "events-event_slug___it",
    path: "/it/events/:event_slug()",
    meta: _91event_slug_936OcSwlcPsfyxVzUMON__ZGf_45EYaDUpH2xHgnkBUaa_45IMeta || {},
    alias: ["/it/hubs/:hub_slug?/events/:event_slug"],
    component: () => import("/app/apps/website/app/pages/events/[event_slug].vue")
  },
  {
    name: "events-event_slug___ja",
    path: "/ja/events/:event_slug()",
    meta: _91event_slug_936OcSwlcPsfyxVzUMON__ZGf_45EYaDUpH2xHgnkBUaa_45IMeta || {},
    alias: ["/ja/hubs/:hub_slug?/events/:event_slug"],
    component: () => import("/app/apps/website/app/pages/events/[event_slug].vue")
  },
  {
    name: "events-event_slug___ko",
    path: "/ko/events/:event_slug()",
    meta: _91event_slug_936OcSwlcPsfyxVzUMON__ZGf_45EYaDUpH2xHgnkBUaa_45IMeta || {},
    alias: ["/ko/hubs/:hub_slug?/events/:event_slug"],
    component: () => import("/app/apps/website/app/pages/events/[event_slug].vue")
  },
  {
    name: "events-event_slug___pl",
    path: "/pl/events/:event_slug()",
    meta: _91event_slug_936OcSwlcPsfyxVzUMON__ZGf_45EYaDUpH2xHgnkBUaa_45IMeta || {},
    alias: ["/pl/hubs/:hub_slug?/events/:event_slug"],
    component: () => import("/app/apps/website/app/pages/events/[event_slug].vue")
  },
  {
    name: "events-event_slug___pt",
    path: "/pt/events/:event_slug()",
    meta: _91event_slug_936OcSwlcPsfyxVzUMON__ZGf_45EYaDUpH2xHgnkBUaa_45IMeta || {},
    alias: ["/pt/hubs/:hub_slug?/events/:event_slug"],
    component: () => import("/app/apps/website/app/pages/events/[event_slug].vue")
  },
  {
    name: "events-event_slug___ru",
    path: "/ru/events/:event_slug()",
    meta: _91event_slug_936OcSwlcPsfyxVzUMON__ZGf_45EYaDUpH2xHgnkBUaa_45IMeta || {},
    alias: ["/ru/hubs/:hub_slug?/events/:event_slug"],
    component: () => import("/app/apps/website/app/pages/events/[event_slug].vue")
  },
  {
    name: "events-event_slug___tr",
    path: "/tr/events/:event_slug()",
    meta: _91event_slug_936OcSwlcPsfyxVzUMON__ZGf_45EYaDUpH2xHgnkBUaa_45IMeta || {},
    alias: ["/tr/hubs/:hub_slug?/events/:event_slug"],
    component: () => import("/app/apps/website/app/pages/events/[event_slug].vue")
  },
  {
    name: "events-event_slug___uk",
    path: "/uk/events/:event_slug()",
    meta: _91event_slug_936OcSwlcPsfyxVzUMON__ZGf_45EYaDUpH2xHgnkBUaa_45IMeta || {},
    alias: ["/uk/hubs/:hub_slug?/events/:event_slug"],
    component: () => import("/app/apps/website/app/pages/events/[event_slug].vue")
  },
  {
    name: "events-event_slug___zh",
    path: "/zh/events/:event_slug()",
    meta: _91event_slug_936OcSwlcPsfyxVzUMON__ZGf_45EYaDUpH2xHgnkBUaa_45IMeta || {},
    alias: ["/zh/hubs/:hub_slug?/events/:event_slug"],
    component: () => import("/app/apps/website/app/pages/events/[event_slug].vue")
  },
  {
    name: "mighty-networks-alternative___de",
    path: "/de/mighty-networks-alternative",
    meta: mighty_45networks_45alternativeRhcGc8CQzNbUDhvQQ2nb3lrkiidX9euxnZorRCklBrgMeta || {},
    component: () => import("/app/apps/website/app/pages/mighty-networks-alternative.vue")
  },
  {
    name: "mighty-networks-alternative___en",
    path: "/mighty-networks-alternative",
    meta: mighty_45networks_45alternativeRhcGc8CQzNbUDhvQQ2nb3lrkiidX9euxnZorRCklBrgMeta || {},
    component: () => import("/app/apps/website/app/pages/mighty-networks-alternative.vue")
  },
  {
    name: "mighty-networks-alternative___es",
    path: "/es/mighty-networks-alternative",
    meta: mighty_45networks_45alternativeRhcGc8CQzNbUDhvQQ2nb3lrkiidX9euxnZorRCklBrgMeta || {},
    component: () => import("/app/apps/website/app/pages/mighty-networks-alternative.vue")
  },
  {
    name: "mighty-networks-alternative___fr",
    path: "/fr/mighty-networks-alternative",
    meta: mighty_45networks_45alternativeRhcGc8CQzNbUDhvQQ2nb3lrkiidX9euxnZorRCklBrgMeta || {},
    component: () => import("/app/apps/website/app/pages/mighty-networks-alternative.vue")
  },
  {
    name: "mighty-networks-alternative___id",
    path: "/id/mighty-networks-alternative",
    meta: mighty_45networks_45alternativeRhcGc8CQzNbUDhvQQ2nb3lrkiidX9euxnZorRCklBrgMeta || {},
    component: () => import("/app/apps/website/app/pages/mighty-networks-alternative.vue")
  },
  {
    name: "mighty-networks-alternative___it",
    path: "/it/mighty-networks-alternative",
    meta: mighty_45networks_45alternativeRhcGc8CQzNbUDhvQQ2nb3lrkiidX9euxnZorRCklBrgMeta || {},
    component: () => import("/app/apps/website/app/pages/mighty-networks-alternative.vue")
  },
  {
    name: "mighty-networks-alternative___ja",
    path: "/ja/mighty-networks-alternative",
    meta: mighty_45networks_45alternativeRhcGc8CQzNbUDhvQQ2nb3lrkiidX9euxnZorRCklBrgMeta || {},
    component: () => import("/app/apps/website/app/pages/mighty-networks-alternative.vue")
  },
  {
    name: "mighty-networks-alternative___ko",
    path: "/ko/mighty-networks-alternative",
    meta: mighty_45networks_45alternativeRhcGc8CQzNbUDhvQQ2nb3lrkiidX9euxnZorRCklBrgMeta || {},
    component: () => import("/app/apps/website/app/pages/mighty-networks-alternative.vue")
  },
  {
    name: "mighty-networks-alternative___pl",
    path: "/pl/mighty-networks-alternative",
    meta: mighty_45networks_45alternativeRhcGc8CQzNbUDhvQQ2nb3lrkiidX9euxnZorRCklBrgMeta || {},
    component: () => import("/app/apps/website/app/pages/mighty-networks-alternative.vue")
  },
  {
    name: "mighty-networks-alternative___pt",
    path: "/pt/mighty-networks-alternative",
    meta: mighty_45networks_45alternativeRhcGc8CQzNbUDhvQQ2nb3lrkiidX9euxnZorRCklBrgMeta || {},
    component: () => import("/app/apps/website/app/pages/mighty-networks-alternative.vue")
  },
  {
    name: "mighty-networks-alternative___ru",
    path: "/ru/mighty-networks-alternative",
    meta: mighty_45networks_45alternativeRhcGc8CQzNbUDhvQQ2nb3lrkiidX9euxnZorRCklBrgMeta || {},
    component: () => import("/app/apps/website/app/pages/mighty-networks-alternative.vue")
  },
  {
    name: "mighty-networks-alternative___tr",
    path: "/tr/mighty-networks-alternative",
    meta: mighty_45networks_45alternativeRhcGc8CQzNbUDhvQQ2nb3lrkiidX9euxnZorRCklBrgMeta || {},
    component: () => import("/app/apps/website/app/pages/mighty-networks-alternative.vue")
  },
  {
    name: "mighty-networks-alternative___uk",
    path: "/uk/mighty-networks-alternative",
    meta: mighty_45networks_45alternativeRhcGc8CQzNbUDhvQQ2nb3lrkiidX9euxnZorRCklBrgMeta || {},
    component: () => import("/app/apps/website/app/pages/mighty-networks-alternative.vue")
  },
  {
    name: "mighty-networks-alternative___zh",
    path: "/zh/mighty-networks-alternative",
    meta: mighty_45networks_45alternativeRhcGc8CQzNbUDhvQQ2nb3lrkiidX9euxnZorRCklBrgMeta || {},
    component: () => import("/app/apps/website/app/pages/mighty-networks-alternative.vue")
  },
  {
    name: component_45stubj39ROnQbHmm0ofaoc5O5P6SgTynbhEUJQMKn6soiApUMeta?.name,
    path: "/de/home",
    component: component_45stubj39ROnQbHmm0ofaoc5O5P6SgTynbhEUJQMKn6soiApU
  },
  {
    name: component_45stubj39ROnQbHmm0ofaoc5O5P6SgTynbhEUJQMKn6soiApUMeta?.name,
    path: "/home",
    component: component_45stubj39ROnQbHmm0ofaoc5O5P6SgTynbhEUJQMKn6soiApU
  },
  {
    name: component_45stubj39ROnQbHmm0ofaoc5O5P6SgTynbhEUJQMKn6soiApUMeta?.name,
    path: "/es/home",
    component: component_45stubj39ROnQbHmm0ofaoc5O5P6SgTynbhEUJQMKn6soiApU
  },
  {
    name: component_45stubj39ROnQbHmm0ofaoc5O5P6SgTynbhEUJQMKn6soiApUMeta?.name,
    path: "/fr/home",
    component: component_45stubj39ROnQbHmm0ofaoc5O5P6SgTynbhEUJQMKn6soiApU
  },
  {
    name: component_45stubj39ROnQbHmm0ofaoc5O5P6SgTynbhEUJQMKn6soiApUMeta?.name,
    path: "/id/home",
    component: component_45stubj39ROnQbHmm0ofaoc5O5P6SgTynbhEUJQMKn6soiApU
  },
  {
    name: component_45stubj39ROnQbHmm0ofaoc5O5P6SgTynbhEUJQMKn6soiApUMeta?.name,
    path: "/it/home",
    component: component_45stubj39ROnQbHmm0ofaoc5O5P6SgTynbhEUJQMKn6soiApU
  },
  {
    name: component_45stubj39ROnQbHmm0ofaoc5O5P6SgTynbhEUJQMKn6soiApUMeta?.name,
    path: "/ja/home",
    component: component_45stubj39ROnQbHmm0ofaoc5O5P6SgTynbhEUJQMKn6soiApU
  },
  {
    name: component_45stubj39ROnQbHmm0ofaoc5O5P6SgTynbhEUJQMKn6soiApUMeta?.name,
    path: "/ko/home",
    component: component_45stubj39ROnQbHmm0ofaoc5O5P6SgTynbhEUJQMKn6soiApU
  },
  {
    name: component_45stubj39ROnQbHmm0ofaoc5O5P6SgTynbhEUJQMKn6soiApUMeta?.name,
    path: "/pl/home",
    component: component_45stubj39ROnQbHmm0ofaoc5O5P6SgTynbhEUJQMKn6soiApU
  },
  {
    name: component_45stubj39ROnQbHmm0ofaoc5O5P6SgTynbhEUJQMKn6soiApUMeta?.name,
    path: "/pt/home",
    component: component_45stubj39ROnQbHmm0ofaoc5O5P6SgTynbhEUJQMKn6soiApU
  },
  {
    name: component_45stubj39ROnQbHmm0ofaoc5O5P6SgTynbhEUJQMKn6soiApUMeta?.name,
    path: "/ru/home",
    component: component_45stubj39ROnQbHmm0ofaoc5O5P6SgTynbhEUJQMKn6soiApU
  },
  {
    name: component_45stubj39ROnQbHmm0ofaoc5O5P6SgTynbhEUJQMKn6soiApUMeta?.name,
    path: "/tr/home",
    component: component_45stubj39ROnQbHmm0ofaoc5O5P6SgTynbhEUJQMKn6soiApU
  },
  {
    name: component_45stubj39ROnQbHmm0ofaoc5O5P6SgTynbhEUJQMKn6soiApUMeta?.name,
    path: "/uk/home",
    component: component_45stubj39ROnQbHmm0ofaoc5O5P6SgTynbhEUJQMKn6soiApU
  },
  {
    name: component_45stubj39ROnQbHmm0ofaoc5O5P6SgTynbhEUJQMKn6soiApUMeta?.name,
    path: "/zh/home",
    component: component_45stubj39ROnQbHmm0ofaoc5O5P6SgTynbhEUJQMKn6soiApU
  },
  {
    name: component_45stubj39ROnQbHmm0ofaoc5O5P6SgTynbhEUJQMKn6soiApUMeta?.name,
    path: "/de/index.html",
    component: component_45stubj39ROnQbHmm0ofaoc5O5P6SgTynbhEUJQMKn6soiApU
  },
  {
    name: component_45stubj39ROnQbHmm0ofaoc5O5P6SgTynbhEUJQMKn6soiApUMeta?.name,
    path: "/index.html",
    component: component_45stubj39ROnQbHmm0ofaoc5O5P6SgTynbhEUJQMKn6soiApU
  },
  {
    name: component_45stubj39ROnQbHmm0ofaoc5O5P6SgTynbhEUJQMKn6soiApUMeta?.name,
    path: "/es/index.html",
    component: component_45stubj39ROnQbHmm0ofaoc5O5P6SgTynbhEUJQMKn6soiApU
  },
  {
    name: component_45stubj39ROnQbHmm0ofaoc5O5P6SgTynbhEUJQMKn6soiApUMeta?.name,
    path: "/fr/index.html",
    component: component_45stubj39ROnQbHmm0ofaoc5O5P6SgTynbhEUJQMKn6soiApU
  },
  {
    name: component_45stubj39ROnQbHmm0ofaoc5O5P6SgTynbhEUJQMKn6soiApUMeta?.name,
    path: "/id/index.html",
    component: component_45stubj39ROnQbHmm0ofaoc5O5P6SgTynbhEUJQMKn6soiApU
  },
  {
    name: component_45stubj39ROnQbHmm0ofaoc5O5P6SgTynbhEUJQMKn6soiApUMeta?.name,
    path: "/it/index.html",
    component: component_45stubj39ROnQbHmm0ofaoc5O5P6SgTynbhEUJQMKn6soiApU
  },
  {
    name: component_45stubj39ROnQbHmm0ofaoc5O5P6SgTynbhEUJQMKn6soiApUMeta?.name,
    path: "/ja/index.html",
    component: component_45stubj39ROnQbHmm0ofaoc5O5P6SgTynbhEUJQMKn6soiApU
  },
  {
    name: component_45stubj39ROnQbHmm0ofaoc5O5P6SgTynbhEUJQMKn6soiApUMeta?.name,
    path: "/ko/index.html",
    component: component_45stubj39ROnQbHmm0ofaoc5O5P6SgTynbhEUJQMKn6soiApU
  },
  {
    name: component_45stubj39ROnQbHmm0ofaoc5O5P6SgTynbhEUJQMKn6soiApUMeta?.name,
    path: "/pl/index.html",
    component: component_45stubj39ROnQbHmm0ofaoc5O5P6SgTynbhEUJQMKn6soiApU
  },
  {
    name: component_45stubj39ROnQbHmm0ofaoc5O5P6SgTynbhEUJQMKn6soiApUMeta?.name,
    path: "/pt/index.html",
    component: component_45stubj39ROnQbHmm0ofaoc5O5P6SgTynbhEUJQMKn6soiApU
  },
  {
    name: component_45stubj39ROnQbHmm0ofaoc5O5P6SgTynbhEUJQMKn6soiApUMeta?.name,
    path: "/ru/index.html",
    component: component_45stubj39ROnQbHmm0ofaoc5O5P6SgTynbhEUJQMKn6soiApU
  },
  {
    name: component_45stubj39ROnQbHmm0ofaoc5O5P6SgTynbhEUJQMKn6soiApUMeta?.name,
    path: "/tr/index.html",
    component: component_45stubj39ROnQbHmm0ofaoc5O5P6SgTynbhEUJQMKn6soiApU
  },
  {
    name: component_45stubj39ROnQbHmm0ofaoc5O5P6SgTynbhEUJQMKn6soiApUMeta?.name,
    path: "/uk/index.html",
    component: component_45stubj39ROnQbHmm0ofaoc5O5P6SgTynbhEUJQMKn6soiApU
  },
  {
    name: component_45stubj39ROnQbHmm0ofaoc5O5P6SgTynbhEUJQMKn6soiApUMeta?.name,
    path: "/zh/index.html",
    component: component_45stubj39ROnQbHmm0ofaoc5O5P6SgTynbhEUJQMKn6soiApU
  },
  {
    name: component_45stubj39ROnQbHmm0ofaoc5O5P6SgTynbhEUJQMKn6soiApUMeta?.name,
    path: "/de/index.php",
    component: component_45stubj39ROnQbHmm0ofaoc5O5P6SgTynbhEUJQMKn6soiApU
  },
  {
    name: component_45stubj39ROnQbHmm0ofaoc5O5P6SgTynbhEUJQMKn6soiApUMeta?.name,
    path: "/index.php",
    component: component_45stubj39ROnQbHmm0ofaoc5O5P6SgTynbhEUJQMKn6soiApU
  },
  {
    name: component_45stubj39ROnQbHmm0ofaoc5O5P6SgTynbhEUJQMKn6soiApUMeta?.name,
    path: "/es/index.php",
    component: component_45stubj39ROnQbHmm0ofaoc5O5P6SgTynbhEUJQMKn6soiApU
  },
  {
    name: component_45stubj39ROnQbHmm0ofaoc5O5P6SgTynbhEUJQMKn6soiApUMeta?.name,
    path: "/fr/index.php",
    component: component_45stubj39ROnQbHmm0ofaoc5O5P6SgTynbhEUJQMKn6soiApU
  },
  {
    name: component_45stubj39ROnQbHmm0ofaoc5O5P6SgTynbhEUJQMKn6soiApUMeta?.name,
    path: "/id/index.php",
    component: component_45stubj39ROnQbHmm0ofaoc5O5P6SgTynbhEUJQMKn6soiApU
  },
  {
    name: component_45stubj39ROnQbHmm0ofaoc5O5P6SgTynbhEUJQMKn6soiApUMeta?.name,
    path: "/it/index.php",
    component: component_45stubj39ROnQbHmm0ofaoc5O5P6SgTynbhEUJQMKn6soiApU
  },
  {
    name: component_45stubj39ROnQbHmm0ofaoc5O5P6SgTynbhEUJQMKn6soiApUMeta?.name,
    path: "/ja/index.php",
    component: component_45stubj39ROnQbHmm0ofaoc5O5P6SgTynbhEUJQMKn6soiApU
  },
  {
    name: component_45stubj39ROnQbHmm0ofaoc5O5P6SgTynbhEUJQMKn6soiApUMeta?.name,
    path: "/ko/index.php",
    component: component_45stubj39ROnQbHmm0ofaoc5O5P6SgTynbhEUJQMKn6soiApU
  },
  {
    name: component_45stubj39ROnQbHmm0ofaoc5O5P6SgTynbhEUJQMKn6soiApUMeta?.name,
    path: "/pl/index.php",
    component: component_45stubj39ROnQbHmm0ofaoc5O5P6SgTynbhEUJQMKn6soiApU
  },
  {
    name: component_45stubj39ROnQbHmm0ofaoc5O5P6SgTynbhEUJQMKn6soiApUMeta?.name,
    path: "/pt/index.php",
    component: component_45stubj39ROnQbHmm0ofaoc5O5P6SgTynbhEUJQMKn6soiApU
  },
  {
    name: component_45stubj39ROnQbHmm0ofaoc5O5P6SgTynbhEUJQMKn6soiApUMeta?.name,
    path: "/ru/index.php",
    component: component_45stubj39ROnQbHmm0ofaoc5O5P6SgTynbhEUJQMKn6soiApU
  },
  {
    name: component_45stubj39ROnQbHmm0ofaoc5O5P6SgTynbhEUJQMKn6soiApUMeta?.name,
    path: "/tr/index.php",
    component: component_45stubj39ROnQbHmm0ofaoc5O5P6SgTynbhEUJQMKn6soiApU
  },
  {
    name: component_45stubj39ROnQbHmm0ofaoc5O5P6SgTynbhEUJQMKn6soiApUMeta?.name,
    path: "/uk/index.php",
    component: component_45stubj39ROnQbHmm0ofaoc5O5P6SgTynbhEUJQMKn6soiApU
  },
  {
    name: component_45stubj39ROnQbHmm0ofaoc5O5P6SgTynbhEUJQMKn6soiApUMeta?.name,
    path: "/zh/index.php",
    component: component_45stubj39ROnQbHmm0ofaoc5O5P6SgTynbhEUJQMKn6soiApU
  },
  {
    name: component_45stubj39ROnQbHmm0ofaoc5O5P6SgTynbhEUJQMKn6soiApUMeta?.name,
    path: "/de/api",
    component: component_45stubj39ROnQbHmm0ofaoc5O5P6SgTynbhEUJQMKn6soiApU
  },
  {
    name: component_45stubj39ROnQbHmm0ofaoc5O5P6SgTynbhEUJQMKn6soiApUMeta?.name,
    path: "/api",
    component: component_45stubj39ROnQbHmm0ofaoc5O5P6SgTynbhEUJQMKn6soiApU
  },
  {
    name: component_45stubj39ROnQbHmm0ofaoc5O5P6SgTynbhEUJQMKn6soiApUMeta?.name,
    path: "/es/api",
    component: component_45stubj39ROnQbHmm0ofaoc5O5P6SgTynbhEUJQMKn6soiApU
  },
  {
    name: component_45stubj39ROnQbHmm0ofaoc5O5P6SgTynbhEUJQMKn6soiApUMeta?.name,
    path: "/fr/api",
    component: component_45stubj39ROnQbHmm0ofaoc5O5P6SgTynbhEUJQMKn6soiApU
  },
  {
    name: component_45stubj39ROnQbHmm0ofaoc5O5P6SgTynbhEUJQMKn6soiApUMeta?.name,
    path: "/id/api",
    component: component_45stubj39ROnQbHmm0ofaoc5O5P6SgTynbhEUJQMKn6soiApU
  },
  {
    name: component_45stubj39ROnQbHmm0ofaoc5O5P6SgTynbhEUJQMKn6soiApUMeta?.name,
    path: "/it/api",
    component: component_45stubj39ROnQbHmm0ofaoc5O5P6SgTynbhEUJQMKn6soiApU
  },
  {
    name: component_45stubj39ROnQbHmm0ofaoc5O5P6SgTynbhEUJQMKn6soiApUMeta?.name,
    path: "/ja/api",
    component: component_45stubj39ROnQbHmm0ofaoc5O5P6SgTynbhEUJQMKn6soiApU
  },
  {
    name: component_45stubj39ROnQbHmm0ofaoc5O5P6SgTynbhEUJQMKn6soiApUMeta?.name,
    path: "/ko/api",
    component: component_45stubj39ROnQbHmm0ofaoc5O5P6SgTynbhEUJQMKn6soiApU
  },
  {
    name: component_45stubj39ROnQbHmm0ofaoc5O5P6SgTynbhEUJQMKn6soiApUMeta?.name,
    path: "/pl/api",
    component: component_45stubj39ROnQbHmm0ofaoc5O5P6SgTynbhEUJQMKn6soiApU
  },
  {
    name: component_45stubj39ROnQbHmm0ofaoc5O5P6SgTynbhEUJQMKn6soiApUMeta?.name,
    path: "/pt/api",
    component: component_45stubj39ROnQbHmm0ofaoc5O5P6SgTynbhEUJQMKn6soiApU
  },
  {
    name: component_45stubj39ROnQbHmm0ofaoc5O5P6SgTynbhEUJQMKn6soiApUMeta?.name,
    path: "/ru/api",
    component: component_45stubj39ROnQbHmm0ofaoc5O5P6SgTynbhEUJQMKn6soiApU
  },
  {
    name: component_45stubj39ROnQbHmm0ofaoc5O5P6SgTynbhEUJQMKn6soiApUMeta?.name,
    path: "/tr/api",
    component: component_45stubj39ROnQbHmm0ofaoc5O5P6SgTynbhEUJQMKn6soiApU
  },
  {
    name: component_45stubj39ROnQbHmm0ofaoc5O5P6SgTynbhEUJQMKn6soiApUMeta?.name,
    path: "/uk/api",
    component: component_45stubj39ROnQbHmm0ofaoc5O5P6SgTynbhEUJQMKn6soiApU
  },
  {
    name: component_45stubj39ROnQbHmm0ofaoc5O5P6SgTynbhEUJQMKn6soiApUMeta?.name,
    path: "/zh/api",
    component: component_45stubj39ROnQbHmm0ofaoc5O5P6SgTynbhEUJQMKn6soiApU
  },
  {
    name: component_45stubj39ROnQbHmm0ofaoc5O5P6SgTynbhEUJQMKn6soiApUMeta?.name,
    path: "/de/_nuxt",
    component: component_45stubj39ROnQbHmm0ofaoc5O5P6SgTynbhEUJQMKn6soiApU
  },
  {
    name: component_45stubj39ROnQbHmm0ofaoc5O5P6SgTynbhEUJQMKn6soiApUMeta?.name,
    path: "/_nuxt",
    component: component_45stubj39ROnQbHmm0ofaoc5O5P6SgTynbhEUJQMKn6soiApU
  },
  {
    name: component_45stubj39ROnQbHmm0ofaoc5O5P6SgTynbhEUJQMKn6soiApUMeta?.name,
    path: "/es/_nuxt",
    component: component_45stubj39ROnQbHmm0ofaoc5O5P6SgTynbhEUJQMKn6soiApU
  },
  {
    name: component_45stubj39ROnQbHmm0ofaoc5O5P6SgTynbhEUJQMKn6soiApUMeta?.name,
    path: "/fr/_nuxt",
    component: component_45stubj39ROnQbHmm0ofaoc5O5P6SgTynbhEUJQMKn6soiApU
  },
  {
    name: component_45stubj39ROnQbHmm0ofaoc5O5P6SgTynbhEUJQMKn6soiApUMeta?.name,
    path: "/id/_nuxt",
    component: component_45stubj39ROnQbHmm0ofaoc5O5P6SgTynbhEUJQMKn6soiApU
  },
  {
    name: component_45stubj39ROnQbHmm0ofaoc5O5P6SgTynbhEUJQMKn6soiApUMeta?.name,
    path: "/it/_nuxt",
    component: component_45stubj39ROnQbHmm0ofaoc5O5P6SgTynbhEUJQMKn6soiApU
  },
  {
    name: component_45stubj39ROnQbHmm0ofaoc5O5P6SgTynbhEUJQMKn6soiApUMeta?.name,
    path: "/ja/_nuxt",
    component: component_45stubj39ROnQbHmm0ofaoc5O5P6SgTynbhEUJQMKn6soiApU
  },
  {
    name: component_45stubj39ROnQbHmm0ofaoc5O5P6SgTynbhEUJQMKn6soiApUMeta?.name,
    path: "/ko/_nuxt",
    component: component_45stubj39ROnQbHmm0ofaoc5O5P6SgTynbhEUJQMKn6soiApU
  },
  {
    name: component_45stubj39ROnQbHmm0ofaoc5O5P6SgTynbhEUJQMKn6soiApUMeta?.name,
    path: "/pl/_nuxt",
    component: component_45stubj39ROnQbHmm0ofaoc5O5P6SgTynbhEUJQMKn6soiApU
  },
  {
    name: component_45stubj39ROnQbHmm0ofaoc5O5P6SgTynbhEUJQMKn6soiApUMeta?.name,
    path: "/pt/_nuxt",
    component: component_45stubj39ROnQbHmm0ofaoc5O5P6SgTynbhEUJQMKn6soiApU
  },
  {
    name: component_45stubj39ROnQbHmm0ofaoc5O5P6SgTynbhEUJQMKn6soiApUMeta?.name,
    path: "/ru/_nuxt",
    component: component_45stubj39ROnQbHmm0ofaoc5O5P6SgTynbhEUJQMKn6soiApU
  },
  {
    name: component_45stubj39ROnQbHmm0ofaoc5O5P6SgTynbhEUJQMKn6soiApUMeta?.name,
    path: "/tr/_nuxt",
    component: component_45stubj39ROnQbHmm0ofaoc5O5P6SgTynbhEUJQMKn6soiApU
  },
  {
    name: component_45stubj39ROnQbHmm0ofaoc5O5P6SgTynbhEUJQMKn6soiApUMeta?.name,
    path: "/uk/_nuxt",
    component: component_45stubj39ROnQbHmm0ofaoc5O5P6SgTynbhEUJQMKn6soiApU
  },
  {
    name: component_45stubj39ROnQbHmm0ofaoc5O5P6SgTynbhEUJQMKn6soiApUMeta?.name,
    path: "/zh/_nuxt",
    component: component_45stubj39ROnQbHmm0ofaoc5O5P6SgTynbhEUJQMKn6soiApU
  },
  {
    name: component_45stubj39ROnQbHmm0ofaoc5O5P6SgTynbhEUJQMKn6soiApUMeta?.name,
    path: "/de/lampu2023",
    component: component_45stubj39ROnQbHmm0ofaoc5O5P6SgTynbhEUJQMKn6soiApU
  },
  {
    name: component_45stubj39ROnQbHmm0ofaoc5O5P6SgTynbhEUJQMKn6soiApUMeta?.name,
    path: "/lampu2023",
    component: component_45stubj39ROnQbHmm0ofaoc5O5P6SgTynbhEUJQMKn6soiApU
  },
  {
    name: component_45stubj39ROnQbHmm0ofaoc5O5P6SgTynbhEUJQMKn6soiApUMeta?.name,
    path: "/es/lampu2023",
    component: component_45stubj39ROnQbHmm0ofaoc5O5P6SgTynbhEUJQMKn6soiApU
  },
  {
    name: component_45stubj39ROnQbHmm0ofaoc5O5P6SgTynbhEUJQMKn6soiApUMeta?.name,
    path: "/fr/lampu2023",
    component: component_45stubj39ROnQbHmm0ofaoc5O5P6SgTynbhEUJQMKn6soiApU
  },
  {
    name: component_45stubj39ROnQbHmm0ofaoc5O5P6SgTynbhEUJQMKn6soiApUMeta?.name,
    path: "/id/lampu2023",
    component: component_45stubj39ROnQbHmm0ofaoc5O5P6SgTynbhEUJQMKn6soiApU
  },
  {
    name: component_45stubj39ROnQbHmm0ofaoc5O5P6SgTynbhEUJQMKn6soiApUMeta?.name,
    path: "/it/lampu2023",
    component: component_45stubj39ROnQbHmm0ofaoc5O5P6SgTynbhEUJQMKn6soiApU
  },
  {
    name: component_45stubj39ROnQbHmm0ofaoc5O5P6SgTynbhEUJQMKn6soiApUMeta?.name,
    path: "/ja/lampu2023",
    component: component_45stubj39ROnQbHmm0ofaoc5O5P6SgTynbhEUJQMKn6soiApU
  },
  {
    name: component_45stubj39ROnQbHmm0ofaoc5O5P6SgTynbhEUJQMKn6soiApUMeta?.name,
    path: "/ko/lampu2023",
    component: component_45stubj39ROnQbHmm0ofaoc5O5P6SgTynbhEUJQMKn6soiApU
  },
  {
    name: component_45stubj39ROnQbHmm0ofaoc5O5P6SgTynbhEUJQMKn6soiApUMeta?.name,
    path: "/pl/lampu2023",
    component: component_45stubj39ROnQbHmm0ofaoc5O5P6SgTynbhEUJQMKn6soiApU
  },
  {
    name: component_45stubj39ROnQbHmm0ofaoc5O5P6SgTynbhEUJQMKn6soiApUMeta?.name,
    path: "/pt/lampu2023",
    component: component_45stubj39ROnQbHmm0ofaoc5O5P6SgTynbhEUJQMKn6soiApU
  },
  {
    name: component_45stubj39ROnQbHmm0ofaoc5O5P6SgTynbhEUJQMKn6soiApUMeta?.name,
    path: "/ru/lampu2023",
    component: component_45stubj39ROnQbHmm0ofaoc5O5P6SgTynbhEUJQMKn6soiApU
  },
  {
    name: component_45stubj39ROnQbHmm0ofaoc5O5P6SgTynbhEUJQMKn6soiApUMeta?.name,
    path: "/tr/lampu2023",
    component: component_45stubj39ROnQbHmm0ofaoc5O5P6SgTynbhEUJQMKn6soiApU
  },
  {
    name: component_45stubj39ROnQbHmm0ofaoc5O5P6SgTynbhEUJQMKn6soiApUMeta?.name,
    path: "/uk/lampu2023",
    component: component_45stubj39ROnQbHmm0ofaoc5O5P6SgTynbhEUJQMKn6soiApU
  },
  {
    name: component_45stubj39ROnQbHmm0ofaoc5O5P6SgTynbhEUJQMKn6soiApUMeta?.name,
    path: "/zh/lampu2023",
    component: component_45stubj39ROnQbHmm0ofaoc5O5P6SgTynbhEUJQMKn6soiApU
  }
]