import type { StrapiWebsiteConfig } from '../types/strapi'

export default defineAppConfig<{
  website: Pick<StrapiWebsiteConfig, 'intercom_app_id' | 'analytic'>
}>({
  // siteName: "Default App Name",
  // themeColor: "#000000",
  // features: {
  //   enableChat: false,
  //   enableDarkMode: false,
  // },

  /**
   * Website config from Strapi
   * set clean field's for type generation
   */
  website: {
    intercom_app_id: '',
    analytic: {
      meta_pixel_id: '',
      google_analytics_id: '',
    },
  },
})
