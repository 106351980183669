// maybe later https://github.com/nuxt/nuxt/issues/21630

export default defineNuxtPlugin(async (nuxtApp) => {
  try {
    const website = await $fetch('/api/config')
    if (website) {
      updateAppConfig({
        website,
      })
    }
  } catch (err) {
    console.error(err)
  }
})
