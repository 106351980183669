import { loggerLink } from '@trpc/client'
import { createTRPCNuxtClient, httpBatchLink } from 'trpc-nuxt/client'
import type { AppRouter } from '~~/server/trpc/routers'
import superjson from 'superjson'

export default defineNuxtPlugin(() => {
  const client = createTRPCNuxtClient<AppRouter>({
    transformer: superjson,
    links: [
      // adds pretty logs to your console in development and logs errors in production
      loggerLink({
        enabled: (opts) =>
          //isDevelopment ||
          opts.direction === 'down' && opts.result instanceof Error,
      }),
      httpBatchLink(),
    ],
  })

  return {
    provide: {
      client,
    },
  }
})
