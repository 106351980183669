<script setup lang="ts">
const props = defineProps({
  error: Object,
})

const { t } = useI18n()

if (props?.error) {
  console.error(props.error)
}
</script>

<template>
  <section class="flex items-center h-full p-16">
    <div
      class="container flex flex-col items-center justify-center px-5 mx-auto my-8"
    >
      <div class="max-w-md text-center">
        <template v-if="error">
          <h2 class="mb-8 font-extrabold text-9xl text-[#B696FF]">
            <span class="sr-only">{{ t('error') }}</span>
            <span>{{ error.statusCode }}</span>
          </h2>

          <p class="text-2xl font-semibold md:text-3xl text-white break-words">
            {{
              error.statusCode === 404
                ? t('page-not-found')
                : t('something-went-wrong')
            }}
          </p>

          <details class="mt-4">
            <summary class="text-[#979797]">
              {{ t('error-page-details') }}
            </summary>
            <pre
              class="text-sm opacity-70 text-left overflow-x-auto whitespace-pre-wrap"
            ><code>{{ error.message }}</code></pre>
          </details>
        </template>

        <p class="mt-4 mb-8 text-[#979797]">
          {{
            t(
              'but-dont-worry-you-can-find-plenty-of-other-things-on-our-homepage'
            )
          }}
        </p>

        <div class="flex justify-center">
          <NuxtLink
            to="/"
            aria-label="t('back-to-homepage')"
            class="border border-[#515151] border-solid rounded-[30px] no-underline text-white flex flex-row items-center px-7 h-11 hover:bg-[#000000cc]"
            rel="noopener noreferrer"
          >
            {{ t('back-to-homepage') }}
          </NuxtLink>
        </div>
      </div>
    </div>
  </section>
</template>
